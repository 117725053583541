import styled from "styled-components";

export const TableStyle = styled.div`
  & {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .table {
    border: 1px solid #FFFFFF;

    .tr {
      &.sticky {
        position: sticky;
        bottom: 0;
      }
      :nth-of-type(odd) {
        background-color: #f6f6f6;
      }
      :nth-of-type(even) {
        background-color: #ffffff;
      }      
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      :hover {
        background-color: #dfdfdf;
      }
    }

    .th,

    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #FFFFFF;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
        background: white;
        font-weight: bold;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
        background-color: white;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
        background-color: white;
      }
    }
  }
`;
