import React, {useMemo} from 'react';
import {useTable, useSortBy, useBlockLayout, useGlobalFilter, useAsyncDebounce} from 'react-table'
import {useSticky} from 'react-table-sticky';
import {useExportData} from "react-table-plugins";
import {TableStyle} from "./tableStyle";
import getExportFileBlob from "../../../../utils/exportTable";
import TableExportButtons from "./tableExportButtons";
import TableGlobalFilter from "./tableGlobalFilter";
import {isNull} from "lodash";

/**
 * Set dynamic width for columns without width definition
 * @param columns
 * @param width
 */
const setTableColumnWidth = (columns, width) =>
{
    // set number of table columns
    const numOfColumns = columns.length;
    // sum total of with in columns objs
    const totalWidthOnColumnsObj = columns.reduce((a, b) => a + (b['width'] || 0), 0);
    // calc total of columns with width definition
    const totalColumnsWithWidth = columns.reduce((a, b) => (b.width) ? ++a : a, 0);

    // if all columns has width do nothing
    if ( numOfColumns === totalColumnsWithWidth) {
        return columns;
    }

    // calculate width for columns without width
    let columnWidth = Math.round((width - totalWidthOnColumnsObj) / (numOfColumns - totalColumnsWithWidth ))
    // set default min value for columns
    columnWidth = (columnWidth < 60) ? 60 : columnWidth;
    // set width for columns without width
    return columns.map(obj => ( (obj.width) ? obj : { ...obj, width: columnWidth }))
}

/**
 * Component to build a react-table with export options
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const TableScrollable = (prop) => {
    let {
        columns,
        data,
        width,
        getExportFileName,
        exportOptions = null,
        withGlobalSearch = false,
        fixedTotalRow = false,
        isSortDisabled = false,
        lastRowsToExcludeFromSort = 0,
    } = prop;

    //memo columns
    columns = useMemo(() => setTableColumnWidth(columns, width) , [columns, width]);

    // set Table
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
        exportData,
    } = useTable(
        {
            columns,
            data,
            getExportFileBlob,
            getExportFileName,
            disableSortBy: isSortDisabled,
        },
        useGlobalFilter,
        useSortBy,
        useExportData,
        useBlockLayout,
        useSticky
    );


    const maxRowIndex = rows.length - 1;
    const rowsExcludedFromSort = rows
        .filter(row => row.index + lastRowsToExcludeFromSort > maxRowIndex)
        .sort((row1, row2) => row1.index > row2.index ? 1 : -1);
        
    const rowsWithoutExcludedFromSort = rows
        .filter(row => !rowsExcludedFromSort.some(lastRow => lastRow.index === row.index));

    return (
        <TableStyle>
            {withGlobalSearch && <TableGlobalFilter filter={state.globalFilter} setFilter={setGlobalFilter} useAsyncDebounce={useAsyncDebounce}/>}
            <div className="table sticky s-table-scrollable" style={{ maxHeight: 600 }} {...getTableProps()}>
                <div className="header">
                    {headerGroups.map((headerGroup) => (
                        <div {...headerGroup.getHeaderGroupProps()} className="tr">
                            {headerGroup.headers.map((column) => (
                                <div {...column.getHeaderProps(column.getSortByToggleProps())} className="th">
                                    {/* can sort indicator */}
                                    <span className={column.canSort ? 'table-sorted-column' : ''}>{column.render('Header')}</span>
                                    {/* sort direction indicator */}
                                    <span>
                                    {column.isSorted
                                        ?
                                            column.isSortedDesc
                                                ?
                                                    ' ▼'
                                                :
                                                    ' ▲'
                                        :
                                            ''
                                    }
                                    </span>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div {...getTableBodyProps()} className="body">
                    {
                        rowsWithoutExcludedFromSort.map((row) => {
                            prepareRow(row);
                            return (
                                <div {...row.getRowProps()} className="tr">
                                    {row.cells.map((cell) => (
                                        <div {...cell.getCellProps()} className="td">
                                            {cell.render('Cell')}
                                        </div>
                                    ))}
                                </div>
                            );
                        })
                    }
                    {
                        rowsExcludedFromSort.map((row) => {
                            prepareRow(row);
                            return (
                                <div {...row.getRowProps()} className={`tr ${fixedTotalRow ? 'sticky' : ''}`}>
                                    {row.cells.map((cell) => (
                                        <div {...cell.getCellProps()} className="td">
                                            {cell.render('Cell')}
                                        </div>
                                    ))}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            {!isNull(exportOptions) &&
                <TableExportButtons exportData={exportData} exportOptions={exportOptions}/>
            }
        </TableStyle>
    );
}

export default TableScrollable;
