import { sortNumericStringColumn } from '../../../common/shared';

const ClientReportRigStatusPerHoleTable = () => {
    return [
        {
            Header: 'Rig',
            accessor: 'rig',
        },
        {
            Header: 'Type',
            accessor: 'rig_type',
        },
        {
            Header: 'Job #',
            accessor: 'job_number',
        },
        {
            Header: 'Project',
            accessor: 'job_name',
        },
        {
            Header: 'Total Project Cost',
            accessor: 'total_revenue',
            sortType: sortNumericStringColumn,
        },
        {
            Header: 'Total Project Depth',
            accessor: 'total_depth',
            sortType: sortNumericStringColumn,
        },
        {
            Header: 'Cost Per FT',
            accessor: 'cost_ft',
            sortType: sortNumericStringColumn,
        },
        {
            Header: 'Cost Per MT',
            accessor: 'cost_mt',
            sortType: sortNumericStringColumn,
        },
        {
            Header: 'Hole',
            accessor: 'hole',
        },
        {
            Header: 'Shifts',
            accessor: 'shifts',
        },
        {
            Header: 'From',
            accessor: 'start_date',
        },
        {
            Header: 'To',
            accessor: 'end_date',
        },
        {
            Header: 'Days',
            accessor: 'days',
        },
    ];
}

export default ClientReportRigStatusPerHoleTable;

