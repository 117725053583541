import React, {useState} from 'react';
import Breadcrumb from "../../../common/breadcrumb";
import LoadingIndicator from "../../../common/loadingIndicator";
import ClientReportRigStatusBody from "./clientReportRigStatusPerHoleBody";
import ClientReportRigStatusBodyPerHoleFilters from './clientReportRigStatusPerHoleFilters';

/**
 * Client report Component
 * @returns {JSX.Element}
 * @constructor
 */
const ClientReportRigStatusPerHole = () => {
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Rig Status Per Hole" parent="Reports" />
            <ClientReportRigStatusBodyPerHoleFilters {...{setFilters, setTableData, onSubmitUrl:"getClientReportRigStatusPerHole"}}/>
            <ClientReportRigStatusBody {...{filters, tableData}} />
            <LoadingIndicator isLoading={filters.isLoading} />
        </>
    );
}

export default ClientReportRigStatusPerHole;