import { Switch, Route, Redirect } from "react-router-dom";
import AppLayout from "../components/AppLayout";
import { UserInfoProvider } from './userInfoProvider';

//TOOLBOX
import ManagementCore from "../components/toolbox/dashboard/ecommerce";
import ToolPusherToolbox from "../components/reports/toolbox/toolpusher_toolbox/toolPusher_toolbox";
import ToolPusherToolboxYTD from "../components/reports/toolbox/toolpusher_toolbox_ytd/toolPusherToolboxYTD";
import DailyReportSummary from "../components/reports/toolbox/daily_report_summary/dailyReportSummary";
import FinancialDashboard from "../components/reports/toolbox/financial_dashboard/financialDashboard";
import WeeklyProduction from "../components/reports/toolbox/weekly_production/weeklyProduction";
import DailyDisciplineRevenue from "../components/reports/revenue_forecast/daily_discipline_revenue/daily_discipline_revenue";
import DailyDisciplineRevenueDetail from "../components/reports/revenue_forecast/daily_discipline_revenue_detail/daily_discipline_revenue_detail";
import DrillerProduction from "../components/reports/toolbox/driller_production/drillerProduction";
import RevenueByRig from "../components/reports/revenue_forecast/revenue_by_rig/revenue_by_rig";
import RevenueByRigDowntime from "../components/reports/revenue_forecast/downtime/downtime";
import RevenueByRigWeekly from "../components/reports/revenue_forecast/revenue_by_rig_weekly/revenue_by_rig_weekly";
import RevenueByHour from "../components/reports/revenue_forecast/revenue_by_hour/revenueByHour";
import JobCostTrackingExport from "../components/reports/toolbox/job_cost_tracking_export/jobCostTrackingExport";
import OperationsSummary from "../components/reports/toolbox/operations_summary/operationsSummary";

// USER SETTINGS
import UserChangePassword from "../components/common/header-component/userChangePassword";

//TIME ENTRY
import CreateOfficeEntryForm from "../components/time_entry/ofice_entry/create_office_entry/createOfficeEntryForm";
import OfficeTimeEntryReview from "../components/time_entry/ofice_entry/review/officeTimeEntryReview";
import OfficeTimeEntrySummary from "../components/time_entry/ofice_entry/summary/officeTimeEntrySummary";
import CreateShopEntryForm from "../components/time_entry/shop_entry/create_shop_entry/createShopEntryForm";
import ShopTimeEntryReview from "../components/time_entry/shop_entry/review/shopTimeEntryReview";
import ShopTimeEntryReviewToApprove from "../components/time_entry/shop_entry/review_to_approve/shopTimeEntryToApprove";
import ShopTimeEntryWorkHistory from "../components/time_entry/shop_entry/work_history/shopTimeEntryHistory";
import ShopTimeEntrySummary from "../components/time_entry/shop_entry/summary/shopTimeEntrySummary";
import ShopTimeEntryDetail from "../components/time_entry/shop_entry/detail/shopTimeEntryDetail";
import ShopTimeEntryMissingTime from "../components/time_entry/shop_entry/missing_time/shopTimeEntryMissingTime";
import ShopTimeEntryWeeklySummary from "../components/time_entry/shop_entry/weekly_summary/shopTimeEntryWeeklySummary";
import EmployeesReview from "../components/time_entry/employees_review/employeesReview";
import CreateDirectEntryForm from "../components/time_entry/create_direct_entry/createDirectEntryForm";
import DirectEntries from "../components/time_entry/direct_entries/direct_entries";

// DATA ENTRY
import FieldDaily from "../components/data_entry/field_daily/field_daily/FieldDaily";
import EditFieldDaily from "../components/data_entry/field_daily/field_daily/FieldDaily";
import FieldDailies from "../components/data_entry/field_daily/field_dailies/field_dailies";
import FieldDailyHoles from "../components/data_entry/field_daily/field_daily_holes/FieldDailyHoles";
import Daily from "../components/data_entry/daily/daily/Daily";
import EditDaily from "../components/data_entry/daily/daily/Daily";
import Dailies from "../components/data_entry/daily/dailies/dailies";
import DailyHoles from "../components/data_entry/daily/daily_holes/daily_holes";
import EmailDistributionList from "../components/data_entry/daily/email_distribution_list/emailDistributionList";

// INVOICING
import CreateInvoice from "../components/invoicing/create_invoice/createInvoice";
import EditInvoice from "../components/invoicing/edit_invoice/editInvoice";
import Invoices from "../components/invoicing/invoices/Invoices";
import ConsumableRegister from "../components/invoicing/consumable_register/consumableRegister";
import InvoiceCharges from "../components/invoicing/invoice_charges/invoice_charges/invoice_charges";
import CreateInvoiceCharges from "../components/invoicing/invoice_charges/create_invoice_charges/CreateInvoiceCharge";

// commercial
import Parents from "../components/commercial/parents/parents/parents";
import CreateParent from "../components/commercial/parents/create_parent";
import Jobs from "../components/commercial/job/jobs/jobs";
import CreateJob from "../components/commercial/job/create_job";
import EditJob from "../components/commercial/job/edit_job/edit_job";
import CreateCharges from "../components/commercial/job/job_charges/jobCharges";
import Locations from "../components/commercial/locations/locations";
import Clients from "../components/commercial/clients/clients";
import CostCodesReport from "../components/commercial/reports/cost_codes_report/costCodesReport";
import FootageProductionReport from "../components/commercial/reports/footage_production_report/footageProductionReport";

// ADMIN
import Users from "../components/admin/users/users/users";
import CreateClient from "../components/admin/users/create_client/CreateClient";
import CreateUser from "../components/admin/users/create_user";
import Rigs from "../components/admin/rigs/rigs/rigs";
import CreateRig from "../components/admin/rigs/create_rig";
import CostCodes from "../components/admin/cost_codes/cost_codes";
import EmailRigList from "../components/admin/rig_emails_deactivated/EmailRigList";
import BillingPeriods from "../components/admin/billing_periods/billing_periods";
import FinancialPeriods from "../components/admin/financial_periods/financial_periods";
import CreateDisciplineTargetByMonth from "../components/admin/discipline_targets/create_discipline_target_by_month";
import DisciplineTargets from "../components/admin/discipline_targets/discipline_targets/disciplineTargets";
import ApiRateLimit from "../components/admin/client_portal_settings/api_rate_limit/api_rate_limit";
import AllocateJobs from "../components/admin/client_portal_settings/allocate_jobs/allocate_jobs";
import BottomHoleDescriptions from "../components/admin/bottom_hole_descriptions/bottom_hole_descriptions";
import OfficeTimeApprovers from "../components/admin/net_settings/office_time_approvers/office_time_approvers";
import ShopTimeApprovers from "../components/admin/net_settings/shop_time_approvers/shop_time_approvers";
import JobsToBypass from "../components/admin/net_settings/jobs_to_bypass/jobs_to_bypass";

// PAYROLL
import CreatePayPeriod from "../components/payroll/pay_periods/create_pay_period/create_pay_period";
import PayPeriods from "../components/payroll/pay_periods/pay_periods/payPeriods";
import EmployeePayableHoursAllHours from "../components/payroll/employee_payable_hours/all_hours/employeePayableHoursAllHours";
import ApprovedUnapprovedHours from "../components/payroll/employee_payable_hours/approved_unapproved_hours/approvedUnapprovedHours";
import SummaryHours from "../components/payroll/employee_payable_hours/summary_hours/summaryHours";
import EmployeeReport from "../components/reports/revenue_forecast/employee_report/employeeReport";

// ACCOUNTING
import ProcessInvoices from "../components/accounting/process_invoices/process_invoices";
import SubmitInvoices from "../components/accounting/submit_invoices/submit_invoices";

// EQUIPMENT
import EditAsset from "../components/equipment/assets/asset/asset";
import CreateAsset from "../components/equipment/assets/asset/asset";
import Assets from "../components/equipment/assets/assets/assets";
import FullWorkHistory from "../components/equipment/assets/full_work_history/fullWorkHistory";

// REPORTS
// import RevenueByRigWeeklyDetail from '../components/reports/revenue_forecast/revenue_by_rig/revenue_weekly_tracking';
import RevenueJobCostTracking from "../components/reports/revenue_forecast/revenue_job_cost_tracking/revenue_job_cost_tracking";
import RigActivities from "../components/reports/revenue_forecast/rig_activities/rig_activities";
import RevenueWeeklyTracking from "../components/reports/revenue_forecast/revenue_weekly_tracking/RevenueWeeklyTracking";
import TransactionRegister from "../components/reports/financial/transaction_register/transaction_register";
import TransactionRegisterDetail from "../components/reports/financial/transaction_register_detail/transaction_register_detail";
import Rec from "../components/reports/financial/rec_report/rec";
import ActiveJobsReport from "../components/reports/accounting/active_jobs_report/activeJobsReport";
import NewVendors from "../components/reports/accounting/new_vendors/newVendors";
import ADPUploadHours from "../components/reports/accounting/adp_upload_hours/adp_upload_hours";
import ADPUploadPerDiem from "../components/reports/accounting/adp_upload_per_diem/adp_upload_per_diem";
import EmployeeRegister from "../components/reports/employee/employee_register/employee_register";
import DirectEntry from "../components/reports/employee/direct_entry/direct_entry";
import ShopTimeEntryReport from "../components/reports/employee/shop_entry/shopTimeEntryReport";
import ShiftReport from "../components/reports/employee/shift_report/shiftReport";
import RigStatus from "../components/reports/production/rig_status/rigStatus";
import Search from "../components/search/searchpage";

// VIEWPOINT REPORTS
import JobCostPO from "../components/reports/viewpoint/job_cost_po/job_cost_po";
import JobCost from "../components/reports/viewpoint/job_cost/job_cost";

// CLIENT PORTAL
import ClientDashboard from "../components/toolbox/dashboard/clientDashboard";
import ClientDailies from "../components/client_portal/dailies/clientDailies";
import ClientInvoices from "../components/client_portal/invoices/clientInvoices";
import ClientJobs from "../components/client_portal/jobs/clientJobs";
import ClientUsers from "../components/client_portal/users/clientUsers";
import ClientReportRigStatus from "../components/client_portal/reports/rig_status/clientReportRigStatus";
import ClientReportRigStatusPerHole from "../components/client_portal/reports/rig_status_per_hole/clientReportRigStatusPerHole";

import { useAuthenticate } from "../hooks/auth";


export const PrivatePages = () => {
    const { user } = useAuthenticate();
    
    return (
        <UserInfoProvider>
            <Switch>
                <AppLayout>
                    {/* dashboard menu */}
                    <Route
                        exact
                        path="/"
                        render={() => {
                            const CLIENT_GROUP_ID = 5;
                            if (user?.group_id === CLIENT_GROUP_ID) {
                                return <Redirect to="/dashboard/client" />;
                            }
                            return <Redirect to="/dashboard/management_core" />;
                        }}
                    />
                    <Route
                        path="/toolbox/dashboard/management_core"
                        component={ManagementCore}
                    />
                    <Route path="/dashboard/client" component={ClientDashboard} />
                    {/*User Settings */}
                    <Route
                        path="/user/settings/change_password"
                        component={UserChangePassword}
                    />
                    {/*TimeEntry Menu */}
                    <Route
                        path="/time_entry/office_time/create_office_entry"
                        component={CreateOfficeEntryForm}
                    />
                    <Route
                        path="/time_entry/office_time/review"
                        component={OfficeTimeEntryReview}
                    />
                    <Route
                        path="/time_entry/office_time/summary"
                        component={OfficeTimeEntrySummary}
                    />
                    <Route
                        path="/time_entry/shop_entry/create_shop_entry"
                        component={CreateShopEntryForm}
                    />
                    <Route
                        path="/time_entry/shop_entry/review"
                        component={ShopTimeEntryReview}
                    />
                    <Route
                        path="/time_entry/shop_entry/review_to_approve"
                        component={ShopTimeEntryReviewToApprove}
                    />
                    <Route
                        path="/time_entry/shop_entry/work_history"
                        component={ShopTimeEntryWorkHistory}
                    />
                    <Route
                        path="/time_entry/shop_entry/summary"
                        component={ShopTimeEntrySummary}
                    />
                    <Route
                        path="/time_entry/shop_entry/missing_time"
                        component={ShopTimeEntryMissingTime}
                    />
                    <Route
                        path="/time_entry/shop_entry/detail"
                        component={ShopTimeEntryDetail}
                    />
                    <Route
                        path="/time_entry/shop_entry/weekly_summary"
                        component={ShopTimeEntryWeeklySummary}
                    />

                    {/*Admin Menu*/}
                    <Route path="/admin/users/users/users" component={Users} />
                    <Route
                        path="/admin/users/create_client/:action/:id?"
                        render={(props) => (
                            <CreateClient {...props} />
                        )}
                    />
                    <Route path="/admin/users/create_user" component={CreateUser} />
                    <Route path="/admin/cost_codes" component={CostCodes} />
                    <Route path="/admin/rig_emails" component={EmailRigList} />
                    <Route
                        path="/admin/financial_periods"
                        component={FinancialPeriods}
                    />
                    <Route
                        path="/admin/billing_periods"
                        component={BillingPeriods}
                    />
                    <Route
                        path="/admin/bottom_hole_descriptions"
                        component={BottomHoleDescriptions}
                    />
                    <Route
                        path="/admin/net_settings/office_time_approvers"
                        component={OfficeTimeApprovers}
                    />
                    <Route
                        path="/admin/net_settings/jobs_to_bypass"
                        component={JobsToBypass}
                    />
                    <Route
                        path="/admin/net_settings/shop_time_approvers"
                        component={ShopTimeApprovers}
                    />
                    <Route
                        path="/admin/discipline_targets/create_discipline_target_by_month"
                        component={CreateDisciplineTargetByMonth}
                    />
                    <Route
                        path="/admin/discipline_targets/discipline_targets"
                        component={DisciplineTargets}
                    />
                    <Route
                        path="/admin/client_portal_settings/api_rate_limit"
                        component={ApiRateLimit}
                    />
                    <Route
                        path="/admin/client_portal_settings/allocate_jobs"
                        component={AllocateJobs}
                    />
                    {/*Payroll*/}
                    <Route
                        path="/payroll/pay_periods/create_pay_period"
                        component={CreatePayPeriod}
                    />
                    <Route
                        path="/payroll/pay_periods/pay_periods/"
                        component={PayPeriods}
                    />
                    <Route
                        path="/payroll/employee_payable_hours/all_hours"
                        component={EmployeePayableHoursAllHours}
                    />
                    <Route
                        path="/payroll/employee_payable_hours/approved_hours"
                        component={ApprovedUnapprovedHours}
                    />
                    <Route
                        path="/payroll/employee_payable_hours/unapproved_hours"
                        component={ApprovedUnapprovedHours}
                    />
                    <Route
                        path="/payroll/employee_payable_hours/summary_hours"
                        component={SummaryHours}
                    />

                    {/*Equipment*/}
                    <Route
                        path="/equipment/assets/asset/asset/:action"
                        render={(props) => (
                            <CreateAsset {...props} />
                        )}
                    />
                    <Route
                        path="/equipment/assets/asset/asset/:action/:id"
                        render={(props) => (
                            <EditAsset {...props} />
                        )}
                    />
                    <Route path="/admin/rigs/rigs" component={Rigs} />
                    <Route
                        path="/admin/rigs/create_rig"
                        component={CreateRig}
                    />
                    <Route
                        path="/equipment/assets/assets/assets"
                        component={Assets}
                    />
                    <Route
                        path="/equipment/assets/full_work_history"
                        component={FullWorkHistory}
                    />

                    {/*commercial*/}
                    <Route
                        path="/commercial/parents/create_parent"
                        component={CreateParent}
                    />
                    <Route
                        path="/commercial/parents/parents/parents"
                        component={Parents}
                    />
                    <Route path="/commercial/jobs/jobs" component={Jobs} />
                    <Route
                        path="/commercial/job/create_job"
                        component={CreateJob}
                    />
                    <Route path="/commercial/job/edit_job" component={EditJob} />
                    <Route
                        path="/commercial/job/job_charges/create_charges/:action"
                        render={(props) => (
                            <CreateCharges {...props} />
                        )}
                    />
                    <Route path="/commercial/job/locations" component={Locations} />
                    <Route path="/commercial/clients/clients" component={Clients} />
                    <Route path="/commercial/reports/cost_code_report" component={CostCodesReport} />
                    <Route path="/commercial/reports/footage_production_report" component={FootageProductionReport} />

                    {/*Data Entry*/}
                    <Route
                        path="/data_entry/field_daily/field_daily/:action"
                        render={(props) => (
                            <FieldDaily {...props} />
                        )}
                    />
                    <Route
                        path="/data_entry/field_daily/field_daily/:action/:id"
                        render={(props) => (
                            <EditFieldDaily {...props} />
                        )}
                    />
                    <Route
                        path="/data_entry/daily/daily/:action"
                        render={(props) => <Daily {...props} />}
                    />
                    <Route
                        path="/data_entry/daily/daily/:action/:id"
                        render={(props) => (
                            <EditDaily {...props} />
                        )}
                    />
                    <Route
                        path="/data_entry/field_daily/field_dailies/field_dailies"
                        component={FieldDailies}
                    />
                    <Route
                        path="/data_entry/field_daily/field_daily_holes"
                        component={FieldDailyHoles}
                    />
                    <Route
                        path="/data_entry/daily/dailies/dailies"
                        component={Dailies}
                    />
                    <Route
                        path="/data_entry/daily/daily_holes/daily_holes"
                        component={DailyHoles}
                    />
                    <Route
                        path="/invoicing/consumable_register"
                        component={ConsumableRegister}
                    />
                    <Route
                        path="/invoicing/invoice_charges/create_invoice_charges"
                        component={CreateInvoiceCharges}
                    />
                    <Route
                        path="/invoicing/invoice_charges/invoice_charges"
                        component={InvoiceCharges}
                    />
                    <Route
                        path="/data_entry/daily/email_distribution_list"
                        component={EmailDistributionList}
                    />

                    {/*Invoicing*/}
                    <Route
                        path="/invoicing/create_invoice"
                        component={CreateInvoice}
                    />
                    <Route
                        path="/invoicing/edit_invoice/:invoice_id"
                        component={EditInvoice}
                    />
                    <Route path="/invoicing/invoices" component={Invoices} />

                    {/*Reports*/}
                    <Route
                        path="/reports/revenue_forecast/daily_discipline_revenue"
                        component={DailyDisciplineRevenue}
                    />
                    <Route
                        path="/reports/toolbox/driller_production"
                        component={DrillerProduction}
                    />
                    <Route
                        path="/reports/toolbox/operations_summary"
                        component={OperationsSummary}
                    />
                     <Route
                        path="/reports/revenue_forecast/revenue_by_hour"
                        component={RevenueByHour}
                    />
                    <Route
                        path="/reports/revenue_forecast/revenue_by_rig"
                        component={RevenueByRig}
                    />
                    <Route
                        path="/reports/revenue_forecast/revenue_by_rig_weekly"
                        component={RevenueByRigWeekly}
                    />
                    <Route
                        path="/reports/revenue_forecast/revenue_job_cost_tracking/:date_from/:date_to/:job_id"
                        component={RevenueJobCostTracking}
                    />
                    <Route
                        path="/reports/revenue_forecast/downtime/:date_from/:date_to/:job_id"
                        component={RevenueByRigDowntime}
                    />
                    <Route
                        path="/reports/revenue_forecast/job_cost_tracking_export"
                        component={JobCostTrackingExport}
                    />
                    <Route
                        path="/reports/toolbox/toolpusher_toolbox"
                        component={ToolPusherToolbox}
                    />
                    <Route
                        path="/reports/toolbox/toolpusher_toolbox_ytd"
                        component={ToolPusherToolboxYTD}
                    />
                    <Route
                        path="/reports/toolbox/financial_dashboard"
                        component={FinancialDashboard}
                    />
                    <Route
                        path="/reports/toolbox/weekly_production"
                        component={WeeklyProduction}
                    />
                    <Route 
                        path="/reports/revenue_forecast/rig_activities/:date_from/:date_to/:rigId/:jobId/:shiftHrs"
                        component={RigActivities}
                    />
                    <Route
                        path="/reports/revenue_forecast/daily_discipline_revenue_detail"
                        component={DailyDisciplineRevenueDetail}
                    />
                    <Route
                        path="/reports/revenue_forecast/revenue_weekly_tracking/:jobId/:id/:type"
                        component={RevenueWeeklyTracking}
                    />
                    <Route
                        path="/reports/toolbox/daily_report_summary"
                        component={DailyReportSummary}
                    />
                    <Route
                        path="/reports/revenue_forecast/employee_report"
                        component={EmployeeReport}
                    />
                    <Route
                        path="/reports/financial/transaction_register"
                        component={TransactionRegister}
                    />
                    <Route
                        path="/reports/financial/transaction_register_detail"
                        component={TransactionRegisterDetail}
                    />
                    <Route path="/reports/financial/rec_report" component={Rec} />
                    <Route
                        path="/accounting/submit_invoices"
                        component={SubmitInvoices}
                    />
                    <Route
                        path="/accounting/process_invoices"
                        component={ProcessInvoices}
                    />
                    <Route
                        path="/reports/employee/employee_register"
                        component={EmployeeRegister}
                    />
                    <Route
                        path="/reports/employee/direct_entry"
                        component={DirectEntry}
                    />
                    <Route
                        path="/time_entry/create_direct_entry"
                        component={CreateDirectEntryForm}
                    />
                    <Route
                        path="/time_entry/direct_entries"
                        component={DirectEntries}
                    />
                    <Route
                        path="/time_entry/employees_review"
                        component={EmployeesReview}
                    />
                    <Route
                        path="/reports/employee/shop_entry"
                        component={ShopTimeEntryReport}
                    />
                    <Route
                        exact
                        path="/reports/employee/shift_report"
                        component={ShiftReport}
                    />
                    <Route
                        path="/reports/employee/shift_report/:date_from/:date_to/:id/:type"
                        component={ShiftReport}
                    />
                    <Route
                        path="/reports/accounting/active_jobs_report"
                        component={ActiveJobsReport}
                    />
                    <Route
                        path="/reports/accounting/new_vendors"
                        component={NewVendors}
                    />
                    <Route
                        path="/reports/accounting/adp_upload_hours"
                        component={ADPUploadHours}
                    />
                    <Route
                        path="/reports/accounting/adp_upload_per_diem"
                        component={ADPUploadPerDiem}
                    />
                    <Route
                        path="/reports/viewpoint/job_cost_po"
                        component={JobCostPO}
                    />
                    <Route
                        path="/reports/viewpoint/job_cost"
                        component={JobCost}
                    />
                    <Route
                        path="/reports/production/rig_status"
                        component={RigStatus}
                    />

                    {/*Client Portal*/}
                    <Route
                        path="/client_portal/dailies"
                        component={ClientDailies}
                    />
                    <Route
                        path="/client_portal/invoices"
                        component={ClientInvoices}
                    />
                    <Route
                        path="/client_portal/jobs"
                        component={ClientJobs}
                    />
                    <Route
                        path="/client_portal/users"
                        component={ClientUsers}
                    />
                    <Route
                        path="/client_portal/reports/rig_status"
                        component={ClientReportRigStatus}
                    />
                    <Route
                        path="/client_portal/reports/rig_status_per_hole"
                        component={ClientReportRigStatusPerHole}
                    />
                    {/*Search*/}
                    <Route
                        path="/search/search"
                        component={Search}
                    />
                </AppLayout>
            </Switch>
        </UserInfoProvider>
    );
};
