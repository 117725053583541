import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormInputDate from "../../../forms/form-control/FormInputDate";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {isUndefined} from 'lodash';
import {getCurrentPeriodFromArray} from "../../../../utils/arrayHelpers";
import moment from "moment";

/**
 * Filter component for Job Cost Tracking Export
 * @returns {JSX.Element}
 * @constructor
 */
const JobCostTrackingExportFilters = (prop) => {
    const { setIsLoading } = prop;
    const {register, handleSubmit, setValue, errors} = useForm();
    const [disciplines, setDisciplines] = useState([]);
    const [rigs, setRigs] = useState([]);
    const [jobs, setJobs] = useState([]);

    // get list of disciplines
    useEffect(() => {
        apiClient
            .get('getDisciplines')
            .then(response => {
                setDisciplines(response.data.disciplines);
        });
    }, []);

    // discipline selection handler
    const disciplineSelectionHandler = e => {
        const {value} = e.target;

        // get Rigs by Discipline Id
        apiClient
            .post('getAllActiveRigsByDisciplineId', {'discipline_id': value})
            .then(response => {
                const rigItems = response.data.rigs.map(
                    ({id, name}) => ({id: id, name: name})
                );
                setRigs(rigItems);
            });
    }

    // rig selection handler
    const rigSelectionHandler = e => {
        const {value} = e.target;

        // get Jobs by Rig Id
        apiClient
            .post('getActiveJobsByRigId', {'rig_id': value})
            .then(response => {
                const jobItems = response.data.jobs.map(
                    ({id, job_number}) => ({value: id, label: job_number})
                );

                setJobs(jobItems);
            });
    }
    
    // download handler
    const onDownload = (data) => {
        setIsLoading(true);

        //get export file
        apiClient('downloadJobCostTrackingReport', {
            method: 'POST',
            responseType: 'blob', //Force to receive data in a Blob Format
            data: data
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = 'job_cost_tracking.xlsx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }).then(function () {
                setIsLoading(false);
            }).catch(function () {
        });
    }


    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onDownload)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="discipline_id"
                    label="Discipline"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    onChange={disciplineSelectionHandler}
                    options={disciplines}
                    register={register({required: {value: true, message: "required"}})}
                    errors={errors}
                />
                <FormSelect
                    id="rig_id"
                    label="Rig"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    onChange={rigSelectionHandler}
                    options={rigs}
                    register={register({required: {value: true, message: "required"}})}
                    errors={errors}
                />
                 <FormSelect
                    id="job_id"
                    label="Job"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={jobs}
                    register={register({required: {value: true, message: "required"}})}
                    errors={errors}
                />
                <FormInputDate
                    id="date_from"
                    label="From"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={moment().subtract(7, "day").format("YYYY-MM-DD")}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={moment().format("YYYY-MM-DD")}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Download"
                />
            </div>
        </form>
    );
}

export default JobCostTrackingExportFilters;
