import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {useForm, useFieldArray} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import CreateOfficeEntryTable from "./createOfficeEntryTable";
import {toast} from "react-toastify";
import LoadingIndicator from "../../../common/loadingIndicator";
import FormNoteAlert from "../../../forms/form-control/FormNoteAlert";
import {useAuthenticate} from "../../../../hooks/auth";

/**
 * CreateOfficeEntry Component
 * @returns {JSX.Element}
 * @constructor
 */
const CreateOfficeEntryForm = () => {
    const [payPeriod, setPayPeriod] = useState({});
    const [ecOptions, setEcOptions] = useState([]);
    const [users, setUsers] = useState([]);
    const [periodLockedStatus, setPeriodLockedStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {register, control, handleSubmit, errors, reset} = useForm({
        defaultValues: {
            time_entries: [{}], // Set first time entry empty by default
        }
    });
    const {fields, append, remove} = useFieldArray({
        control,
        name: "time_entries"
    });
    const { user } = useAuthenticate();

    // save form handler
    const onSubmit = (data) => {
        setIsLoading(true);
        apiClient
            .post('createOfficeTimeEntries/' + user.id, {
                time_entries: data.time_entries.map(entry => ({
                    ...entry,
                    user_id: entry.user_id ? entry.user_id.value : undefined,
                }))
            })
            .then(response => {
                reset();
                toast.success(response.data.message);
            })
            .catch(function () {
                setIsLoading(false);
            })
            .then(function () {
                setIsLoading(false);
            });
    }

    // get list of options
    useEffect(() => {
        // get period status
        apiClient
            .get('isLocked')
            .then(response => {
                setPeriodLockedStatus(response.data);
            });

        // get current pay period
        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                setPayPeriod(response.data);
            });

        //get EC Options
        apiClient
            .get('getEcOptionsForOffice')
            .then(response => {
                setEcOptions(response.data);
            });
    }, []);

    useEffect(() => {
        if (user?.group_id === 1) {
            apiClient
            .get('getOfficeTimeEntryUsers')
            .then(response => {
                setUsers(
                    response.data.map(
                        ({id, first_name, last_name}) =>
                            ({value: id, label: first_name + ' ' + last_name})
                    )
                );
            });
        }
    }, [user?.group_id]);

    return (
        <>
            <Breadcrumb title="Create Office Time Entry" parent="Time Entry"/>
            <form
                className="needs-validation container-fluid"
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormNoteAlert
                    className={(periodLockedStatus) ? "alert-danger" : "alert-success"}
                    label={
                        (periodLockedStatus)
                            ?
                                'Pay Period is Locked'
                            :
                                'Current Pay Period: ' + payPeriod.start_date + ' - ' +  payPeriod.end_date
                    }
                />
                <CreateOfficeEntryTable
                    register={register}
                    control={control}
                    errors={errors}
                    fields={fields}
                    append={append}
                    remove={remove}
                    ecOptions={ecOptions}
                    payPeriod={payPeriod}
                    users={users}
                />
                <FormSubmitButton
                    label="Create"
                />
            </form>
            <LoadingIndicator isLoading={isLoading}/>
        </>
    );
}

export default CreateOfficeEntryForm;
