import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import {useFieldArray} from "react-hook-form";
import SearchableSelectInTable from "../../../../forms/form-control/daily/SearchableSelectInTable";
import * as Shared from "../../../../common/shared";
import apiClient from "../../../../../utils/apiClient";

const DailyGeneralCharges = (props) => {
    const {
        errors, register, control, collectedHoles, generalDescriptions, selectedJobId, daily, action,
        totalGeneralCharges, setTotalGeneralCharges, setValue
    } = props;
    const {fields, append, remove} = useFieldArray({control, name: "general_charges"});
    const [selectedGeneralChargesHole, setSelectedGeneralChargesHole] = useState('');

    useEffect(() => {
        if (action === 'edit') {
            append(daily.daily_general);

            setTimeout(() => {
                daily.daily_general.forEach((item, index) => {
                    // populate select
                    setValue(
                        `general_charges[${index}].cost_code`,
                        {value: item.cost_code, label: item.description}
                    );
                });
            }, 500);

            setTimeout(() => {
                Shared.calculateTotals('total_general', setTotalGeneralCharges);
            }, 500);
        }
    }, [action, append, daily, setTotalGeneralCharges, setValue]);

    const calculateTotalChargedInRow = (e, price, charged, totalId) => {
        e.preventDefault();

        let priceValue = (document.getElementById(price) !== null) ? document.getElementById(price).value : 0;
        let chargedValue = (document.getElementById(charged) !== null) ? document.getElementById(charged).value : 0;

        if (chargedValue !== 0) {
            let totalRaw = Number(chargedValue) * Number(priceValue);
            let total = Number(totalRaw).toFixed(2);
            document.getElementById(totalId).value = total;

            Shared.calculateTotals('total_general', setTotalGeneralCharges);
        }
    }

    const selectCode = (value, index) => {
        let jobId = (action === 'edit') ? daily.job_id : selectedJobId;
        if (value && jobId) {
            apiClient.post('getJobChargesPriceByCodeAndJobId', {'job_id': jobId, 'code': value})
                .then(response => {
                    const realPrice = parseFloat(response.data[0]).toFixed(2);
                    document.getElementById(`general_charges[${index}].price`).value = realPrice;
                });
        }
    }

    const removeGeneralChargesRow = (index) => {
        remove(index);
        // calculate again
        setTimeout(() => {
            Shared.calculateTotals('total_general', setTotalGeneralCharges);
        }, 500)
    }

    useEffect(() => {
        if (!fields.length) {
            setTotalGeneralCharges(0);
        }
    }, [fields.length, setTotalGeneralCharges])

    return (
            <AccordionItem className="card-header bg-primary" title="General Charges / Equipment">
                <div className="card">
                    {totalGeneralCharges >= 0 &&
                        <div className="form-row" id="general_totals">
                            <div className="col-md-3 mb-3 total_block">
                                <p className="form-control background_total_block_color" id="total">
                                    Total: {
                                        Number(totalGeneralCharges)
                                        .toLocaleString('en-US', {style: 'currency', currency: 'USD'})
                                    }
                                </p>
                            </div>
                        </div>
                    }
                    <div className="form-row">
                        <div className="col-sm-12">
                            <div className="table-responsive">
                                <table className="table table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th>Price</th>
                                            <th>Units</th>
                                            <th>Charged</th>
                                            <th>Total</th>
                                            <th>Hole</th>
                                            <th className="action-cell">
                                                <FaPlus
                                                    color='#22af47'
                                                    size={20}
                                                    title="Add New"
                                                    onClick={() => append({})}
                                                />
                                                <FaEraser
                                                    color='#A51E37'
                                                    className="marginLeft"
                                                    size={20}
                                                    title="Remove All"
                                                    onClick={() => remove()}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fields.map((item, index) => (
                                            <tr key={item.id}>
                                                <SearchableSelectInTable
                                                    id={`general_charges[${index}].cost_code`}
                                                    options={generalDescriptions}
                                                    rules={{ required: "required" }}
                                                    onChange={(value) => selectCode(value, index)}
                                                    value={(action === 'edit') ? item.cost_code?.value : ''}
                                                    control={control}
                                                    errors={errors}
                                                    customStyles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            width: 700,
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            width: 700,
                                                            position: 'sticky',
                                                        })
                                                    }}
                                                />
                                                <InputInTable
                                                    className="price-column-width"
                                                    id={`general_charges[${index}].price`}
                                                    name={`general_charges[${index}].price`}
                                                    register={register({required: false})}
                                                    value={(action === 'edit') ?
                                                        (item.price)
                                                            ?
                                                            Number(item.price).toFixed(2)
                                                            :
                                                            Number(0).toFixed(2)
                                                        :
                                                        ""
                                                    }
                                                    disabled={'true'}
                                                />
                                                <InputInTable
                                                    className="hours-column-width"
                                                    id={`general_charges[${index}].units`}
                                                    name={`general_charges[${index}].units`}
                                                    type="number"
                                                    value={(action === 'edit') ? item.units : ''}
                                                    onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                                <InputInTable
                                                    className="hours-column-width"
                                                    id={`general_charges[${index}].charged`}
                                                    name={`general_charges[${index}].charged`}
                                                    type="number"
                                                    value={(action === 'edit') ? item.charged : ''}
                                                    onChange={(e) => calculateTotalChargedInRow(
                                                        e,
                                                        `general_charges[${index}].price`,
                                                        `general_charges[${index}].charged`,
                                                        `general_charges[${index}].total`
                                                    )}
                                                    onInput={(e) => Shared.limitInputDaily(e.target, 10)}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                                <InputInTable
                                                    className="total_general price-column-width"
                                                    id={`general_charges[${index}].total`}
                                                    name={`general_charges[${index}].total`}
                                                    register={register({required: false})}
                                                    value={(action === 'edit') ? item.total : ''}
                                                    disabled={'true'}
                                                />
                                                {action === 'create' &&
                                                    <SelectInTable
                                                        id={`general_charges[${index}].hole_name`}
                                                        name={`general_charges[${index}].hole_name`}
                                                        options={collectedHoles}
                                                        value={(collectedHoles.length === 1) ? collectedHoles[0].value : '' }
                                                        register={register({required: "required"})}
                                                        errors={errors}
                                                    />
                                                }
                                                {action === 'edit' &&
                                                    <SelectInTable
                                                        id={`general_charges[${index}].hole_name`}
                                                        name={`general_charges[${index}].hole_name`}
                                                        value={
                                                            selectedGeneralChargesHole
                                                                ?
                                                                selectedGeneralChargesHole
                                                                :
                                                                (collectedHoles.length === 1)
                                                                    ?
                                                                    collectedHoles[0].value
                                                                    :
                                                                    item.hole_name
                                                        }
                                                        onChange={
                                                            (e) => Shared.setSelectedValue(e.target.value, setSelectedGeneralChargesHole)
                                                        }
                                                        options={collectedHoles}
                                                        register={register({required: "required"})}
                                                        errors={errors}
                                                    />
                                                }
                                                <td>
                                                    <FaTrash
                                                        color='#A51E37'
                                                        size={20}
                                                        title="Remove"
                                                        onClick={() => removeGeneralChargesRow(index)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </AccordionItem>
    );
}

export default React.memo(DailyGeneralCharges);
