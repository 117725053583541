import React from 'react';
import { useLocation } from 'react-router-dom';
import { BREAKPOINTS } from '../../../utils/constants';
import TableStandard from "./table-standard/table";
import TableScrollable from "./table-scrollable/table";
import TableMobile from './table-mobile';

/**
 * A wrap for react-table to allow to build the table in different ways
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const TableReport = (prop) => {
    let {
        columns,
        data,
        exportOptions = null,
        getExportFileName,
        withGlobalSearch = false,
        scrollable = false,
        fixedTotalRow = false,
        isSortDisabled = false,
        lastRowsToExcludeFromSort = 0,
    } = prop;
    const { pathname } = useLocation();

    const pagesToExclude = [
        '/reports/toolbox/toolpusher_toolbox',
        '/reports/toolbox/toolpusher_toolbox_ytd',
        '/reports/revenue_forecast/revenue_by_rig',
        '/reports/revenue_forecast/daily_discipline_revenue_detail',
        '/reports/revenue_forecast/daily_discipline_revenue',
        '/reports/revenue_forecast/revenue_by_rig_weekly'
    ];

    if (window.innerWidth <= BREAKPOINTS['lg'] && !pagesToExclude.includes(pathname)) {
        return (
            <TableMobile
                {...{
                    columns,
                    data,
                    exportOptions,
                    getExportFileName,
                    withGlobalSearch,
                    fixedTotalRow,
                    isSortDisabled,
                }}
            />
        );
    }

    if (scrollable) {
        return <TableScrollable {...{columns, data, exportOptions,fixedTotalRow, getExportFileName, withGlobalSearch, isSortDisabled, lastRowsToExcludeFromSort}}/>
    }

    return <TableStandard {...{columns, data, exportOptions, getExportFileName, withGlobalSearch, fixedTotalRow, isSortDisabled, lastRowsToExcludeFromSort}}/>
}

export default TableReport;
