import React from 'react';
import TableReport from "../../../common/table/table";
import MessageEmptyData from "../../../common/messageEmptyData";
import {isEmpty} from "lodash"
import {tableColumns} from "./tableColumns";
import OperationsSummaryChart from './operationsSummaryChart';

/**
 * Body report component for daily summary report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const OperationsSummaryBody = (prop) => {
    const {reportFilters, tableData, barChartLabels, billedPercentChartData, revenueChartData, avgRefShiftChartData, ftShiftChartData} = prop;

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (isEmpty(tableData)) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <TableReport
                columns={tableColumns}
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                withGlobalSearch={false}
                scrollable={true}
                fixedTotalRow={true}
                lastRowsToExcludeFromSort={1}
            />
            <div className="row">
                <div className="col-md-6 mb-12">
                    <OperationsSummaryChart
                        title='Billed %'
                        labels={barChartLabels}
                        dataset={billedPercentChartData}
                    />
                </div>
                <div className="col-md-6 mb-12">
                    <OperationsSummaryChart
                        title='Revenue'
                        labels={barChartLabels}
                        dataset={revenueChartData}
                        addDollarSign
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-12">
                    <OperationsSummaryChart
                        title='FT/Shift'
                        labels={barChartLabels}
                        dataset={ftShiftChartData}
                    />
                </div>
                <div className="col-md-6 mb-12">
                    <OperationsSummaryChart
                        title='Avg Rev Shift'
                        labels={barChartLabels}
                        dataset={avgRefShiftChartData}
                        addDollarSign
                    />
                </div>
            </div>
        </div>
    );
}

export default OperationsSummaryBody;
