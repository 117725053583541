import { formatNumberToDisplayWithCommas } from "../../../../components/common/shared";

const ROWS_TO_IGNORE_FORMATTING = ['Target %', 'Date'];

function renderCell(cell) {
    return ROWS_TO_IGNORE_FORMATTING.includes(cell.row.cells[0].value) ? cell.value ?? null : formatNumberToDisplayWithCommas(cell.value);
}

export const tableColumns = [
    {
        Header: '',
        accessor: 'month',
    },
    {
        Header: 'Surface Core',
        accessor: 'discipline_1',
        Cell: renderCell,
    },
    {
        Header: 'Underground Core',
        accessor: 'discipline_2',
        Cell: renderCell,
    },
    {
        Header: 'RCX',
        accessor: 'discipline_3',
        Cell: renderCell,
    },
    {
        Header: 'Rotary',
        accessor: 'discipline_4',
        Cell: renderCell,
    },
    {
        Header: 'Total',
        accessor: 'total',
        Cell: renderCell,
    },
];
