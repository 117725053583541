import styled from "styled-components";

export const TableStyle = styled.div`
  overflow-x: auto;
  table {
    tbody {
      tr {
        :last-child {
          td {
            border-top: 5px double black;
            font-weight: bold;
          }
        }
        td {
          :not(:first-child):nth-child(8n + 1) {
            font-weight: 700;
          }
        }
      }
    }
  }
`;
