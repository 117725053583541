import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import LoadingIndicator from "../../../common/loadingIndicator";
import DrillerProductionFilters from './drillerProductionFilters';
import DrillerProductionBody from './drillerProductionBody';

/**
 * Driller production report component
 * @returns {JSX.Element}
 * @constructor
 */

const DrillerProduction = () => {
    const [tableData, setTableData] = useState([]);
    const [barChartLabels, setBarChartLabels] = useState([]);
    const [barChartData, setBarChartData] = useState([]);
    const [chartAverageValue, setChartAverageValue] = useState(null);
    const [reportFilters, setReportFilters] = useState({
        date: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Driller Production" parent="Toolbox"/>
            <div className="s-driller-production">
                <DrillerProductionFilters {...{tableData, setTableData, reportFilters, setReportFilters, setBarChartData, setBarChartLabels, setChartAverageValue}} />
                <DrillerProductionBody {...{reportFilters, tableData, barChartData, barChartLabels, chartAverageValue}} />
            </div>
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
}

export default DrillerProduction;
