import React from "react";
import {Link} from 'react-router-dom';
import apiClient from "../../../../utils/apiClient";
import {
    FaCheck, FaEyeSlash, FaCheckSquare, FaPencilAlt, FaArrowCircleRight, FaRegFilePdf, FaEye, FaTrash
} from "react-icons/fa";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isNull} from "lodash";

const DEPTH_TOTAL_COLUMN = {
    Header: 'Depth Total',
    accessor: 'depth_total',
}

const CORE_DISCIPLINE_IDS = ['1', '2'];

const NON_CORE_DISCIPLINE_COLUMNS = [
    DEPTH_TOTAL_COLUMN,
    {
        Header: 'Ream Total',
        accessor: 'ream_total',
    },
    {
        Header: 'Injuries',
        accessor: 'injuries',
        Cell: (cell) => {
            const row = cell.row.original;
            switch (row.injuries) {
                case 0:
                    return 'No';
                case 'null':
                    return 'No';
                case 1:
                    return 'Yes';
                default:
                    return null;
            }
        }
    },
]

const CORE_DISCIPLINE_COLUMNS = [
    {
        Header: 'Hole',
        accessor: 'hole',
    },
    {
        Header: 'Depth From',
        accessor: 'depth_from',
    },
    {
        Header: 'Depth To',
        accessor: 'depth_to',
    },
    DEPTH_TOTAL_COLUMN,
    {
        Header: 'Runs',
        accessor: 'runs',
    },
    {
        Header: 'Core Retrieved',
        accessor: 'core_retrieved',
    },
    {
        Header: 'Hours',
        accessor: 'hours',
    },
    {
        Header: 'Unbilled',
        accessor: 'unbilled_hours',
    }
]

const FieldDailiesTable = (
        setFilters, setSelectedRow, setApproveModal, setDisapproveModal,
        setImportModal, setDeactivateModal, setReactivateModal, setDeleteModal,
        selectedDisciplineId, user
    ) => {
    const TOOL_PUSHER = 11;
    let isCoreDisciplineSelected = false;

    // if Toolpusher came to this page we force them to render table based on their discipline_id
    if (user?.group_id === TOOL_PUSHER) {
        isCoreDisciplineSelected = CORE_DISCIPLINE_IDS.includes(user?.discipline_id.toString());
    } else {
        isCoreDisciplineSelected = CORE_DISCIPLINE_IDS.includes(selectedDisciplineId);
    }

    return [
        {
            Header: 'Date',
            accessor: 'date',
            width: 70,
            Cell: (cell) => {
                const row = cell.row.original;
                let result = '';
                result = (row.status > 0)
                    ?
                        (
                            <a
                                href={`/data_entry/field_daily/field_daily/edit/${row.field_daily_id}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <b>{cell.value}</b>
                            </a>
                        )
                    :
                        cell.value;
                return result;
            }
        },
        {
            Header: 'Shift',
            accessor: 'shift',
            width: 45,
        },
        {
            Header: 'Rig',
            accessor: 'rig',
        },
        {
            Header: 'Client',
            accessor: 'client',
        },
        {
            Header: 'Job',
            accessor: 'job',
        },
        {
            Header: 'Location',
            accessor: 'location',
        },
        ...(isCoreDisciplineSelected ? CORE_DISCIPLINE_COLUMNS : NON_CORE_DISCIPLINE_COLUMNS),
        {
            Header: 'TA Date',
            accessor: 'supervisor_approved_date',
            width: 70,
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;

                let active = (
                    <>
                        <Link to={`/data_entry/field_daily/field_daily/edit/${row.field_daily_id}`} target="_blank">
                            <FaPencilAlt className="actions" title="Edit Field Daily"/>
                        </Link>
                        {' '}
                        { row.supervisor_approved > 0 ?
                            <FaCheckSquare
                                className="actions"
                                title="Disapprove Field Daily"
                                color='#22af47'
                                onClick={() => {
                                    const details = row.date + ' ' + row.rig + ' ' + row.job;
                                    const msg = 'Are you sure that you want to disapprove Field daily : ' + details;
                                    setSelectedRow(row);
                                    setDisapproveModal({
                                        message: msg,
                                        isOpen: true,
                                    });
                                }}
                            />
                        :
                            <FaCheck
                                className="actions"
                                title="Approve Field Daily"
                                onClick={() => {
                                    const details = row.date + ' ' + row.rig + ' ' + row.job;
                                    const msg = 'Are you sure that you want to approve Field daily : ' + details;
                                    setSelectedRow(row);
                                    setApproveModal({
                                        message: msg,
                                        isOpen: true,
                                    });
                                }}
                            />
                        }
                        {' '}
                        { user?.group_id === 1 && row.supervisor_approved > 0 &&
                            <FaEyeSlash
                                className="actions"
                                title="Deactivate Field Daily"
                                onClick={() => {
                                    const details = row.date + ' ' + row.rig + ' ' + row.job;
                                    const msg = 'Are you sure that you want to deactivate Field daily : ' + details;
                                    setSelectedRow(row);
                                    setDeactivateModal({
                                        message: msg,
                                        isOpen: true,
                                    });
                                }}
                            />
                        }
                        { row.supervisor_approved === 0 &&
                            <FaEyeSlash
                                className="actions"
                                title="Deactivate Field Daily"
                                onClick={() => {
                                    const details = row.date + ' ' + row.rig + ' ' + row.job;
                                    const msg = 'Are you sure that you want to deactivate Field daily : ' + details;
                                    setSelectedRow(row);
                                    setDeactivateModal({
                                        message: msg,
                                        isOpen: true,
                                    });
                                }}
                            />
                        }
                        {' '}
                        { row.supervisor_approved > 0 &&
                            <FaArrowCircleRight
                                className={(row.imported_by) ? "actions-status" : "actions"}
                                title={(row.imported_by) ? "Imported Field Daily" : "Import Field Daily"}
                                onClick={() => {
                                    if (isNull(row.imported_by)) {
                                        const details = row.date + ' ' + row.rig + ' ' + row.job;
                                        let importMsg = 'Are you sure that you want to import Field Daily: ' + details + ' ?';
                                        setSelectedRow(row);
                                        setImportModal({
                                            message: importMsg,
                                            isOpen: true,
                                        });
                                    }
                                }}
                            />
                        }
                        {' '}
                        <FaRegFilePdf
                            className="actions"
                            title="PDF report"
                            onClick={() => {
                                updateStateValue(setFilters, 'isLoading', true);
                                //get pdf export file and open it in a new window
                                apiClient(`/pdfExportFieldDailyDetailsById/${row.field_daily_id}`, {
                                    method: 'GET',
                                    responseType: 'blob' //Force to receive data in a Blob Format
                                })
                                    .then(response => {
                                        //Create a Blob from the PDF Stream
                                        const file = new Blob(
                                            [response.data],
                                            {type: 'application/pdf'}
                                        );
                                        //Build a URL from the file
                                        const fileURL = URL.createObjectURL(file);
                                        //Open the URL on new Window
                                        window.open(fileURL);
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    }).then(function () {
                                    updateStateValue(setFilters, 'isLoading', false);
                                });
                            }}
                        />
                    </>
                );

                let inactive = (
                    <>
                        { user?.group_id === 1 && row.supervisor_approved > 0 &&
                            <FaEye
                                className="actions"
                                title="Reactivate Field Daily"
                                onClick={() => {
                                    const details = row.date + ' ' + row.rig + ' ' + row.job;
                                    const msg = 'Are you sure that you want to reactivate field daily : ' + details;
                                    setSelectedRow(row);
                                    setReactivateModal({
                                        message: msg,
                                        isOpen: true,
                                    });
                                }}
                            />
                        }
                        { row.supervisor_approved === 0 &&
                            <FaEye
                                className="actions"
                                title="Reactivate Field Daily"
                                onClick={() => {
                                    const details = row.date + ' ' + row.rig + ' ' + row.job;
                                    const msg = 'Are you sure that you want to reactivate field daily : ' + details;
                                    setSelectedRow(row);
                                    setReactivateModal({
                                        message: msg,
                                        isOpen: true,
                                    });
                                }}
                            />
                        }
                        {' '}
                        { user?.group_id === 1 && row.supervisor_approved > 0 &&
                            <FaTrash
                                className="actions"
                                title="Delete Field Daily"
                                onClick={() => {
                                    const details = row.date + ' ' + row.rig + ' ' + row.job;
                                    const msg = 'Are you sure that you want to delete field daily : ' + details;
                                    setSelectedRow(row);
                                    setDeleteModal({
                                        message: msg,
                                        isOpen: true,
                                    })
                                }}
                            />
                        }
                        { row.supervisor_approved === 0 &&
                            <FaTrash
                                className="actions"
                                title="Delete Field Daily"
                                onClick={() => {
                                    const details = row.date + ' ' + row.rig + ' ' + row.job;
                                    const msg = 'Are you sure that you want to delete field daily : ' + details;
                                    setSelectedRow(row);
                                    setDeleteModal({
                                        message: msg,
                                        isOpen: true,
                                    })
                                }}
                            />
                        }
                    </>
                );

                return (row.status === 1) ? active : inactive;
            }
        }
    ];
}

export default FieldDailiesTable;
