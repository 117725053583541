import React, {useCallback, useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import LoadingIndicator from "../../../common/loadingIndicator";
import OperationsSummaryFilters from './operationsSummaryFilters';
import OperationsSummaryBody from './operationsSummaryBody';
import {parseMoneyField} from "../../../common/shared";

/**
 * Operations supervisor report component
 * @returns {JSX.Element}
 * @constructor
 */

const OperationsSummary = () => {
    const [tableData, setTableData] = useState([]);
    const [barChartLabels, setBarChartLabels] = useState([]);
    const [billedPercentChartData, setBilledPercentChartData] = useState([]);
    const [revenueChartData, setRevenueChartData] = useState([]);
    const [avgRefShiftChartData, setAvgRefShiftChartData] = useState([]);
    const [ftShiftChartData, setFtShiftChartData] = useState([]);
    const [reportFilters, setReportFilters] = useState({
        date: '',
        isLoading: false,
        isSubmitted: false,
    });

    const setChartsData = useCallback((operationsSummaryData) => {
        if (operationsSummaryData !== null) {
            setBarChartLabels(operationsSummaryData.map(record => record.rig_name));
            setBilledPercentChartData(operationsSummaryData.map(record => record.percent_billed));
            setRevenueChartData(operationsSummaryData.map(record => parseMoneyField(record.revenue)));
            setAvgRefShiftChartData(operationsSummaryData.map(record => parseMoneyField(record.avg_rev_shift)));
            setFtShiftChartData(operationsSummaryData.map(record => record.footage_per_shift));
        } else {
            setBarChartLabels([]);
            setBilledPercentChartData([]);
            setRevenueChartData([]);
            setAvgRefShiftChartData([]);
            setFtShiftChartData([]);
        }
    }, []);

    return (
        <>
            <Breadcrumb title="Operations Summary" parent="Reports"/>
            <div className="s-operations-supervisor">
                <OperationsSummaryFilters {...{tableData, setTableData, setReportFilters, setChartsData}} />
                <OperationsSummaryBody {...{
                    reportFilters, tableData, barChartLabels, billedPercentChartData, revenueChartData,
                    avgRefShiftChartData, ftShiftChartData}
                } />
            </div>
            <LoadingIndicator isLoading={reportFilters.isLoading}/>
        </>
    );
}

export default OperationsSummary;
