import React from 'react';
import TableReport from "../../../common/table/table";
import {TableStyle} from "./tableStyle";
import {getTableColumns} from "./tableColumns.js";
import MessageEmptyData from "../../../common/messageEmptyData";
import {isEmpty} from "lodash";

/**
 * Body report component for Daily Discipline Revenue Detail
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ReportBody = (prop) => {
    const {
        reportFilters,
        tableDataDiscipline1,
        tableDataDiscipline2,
        tableDataDiscipline3,
        tableDataDiscipline4,
        tableTotalData
    } = prop;

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (isEmpty(tableDataDiscipline1) &&
        isEmpty(tableDataDiscipline2) &&
        isEmpty(tableDataDiscipline3) &&
        isEmpty(tableDataDiscipline4) &&
        isEmpty(tableTotalData)) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    <TableStyle>
                        <TableReport
                            columns={getTableColumns('Surface Core')}
                            data={tableDataDiscipline1}
                            exportOptions={{pdf: true, xlsx: true, csv: false}}
                            getExportFileName={() => 'surface_core_daily_discipline_revenue_detail'}
                            isSortDisabled={true}
                        />
                    </TableStyle>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    <TableStyle>
                        <TableReport
                            columns={getTableColumns('Underground Core')}
                            data={tableDataDiscipline2}
                            exportOptions={{pdf: true, xlsx: true, csv: false}}
                            getExportFileName={() => 'underground_core_daily_discipline_revenue_detail'}
                            isSortDisabled={true}
                        />
                    </TableStyle>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    <TableStyle>
                        <TableReport
                            columns={getTableColumns('RCX')}
                            data={tableDataDiscipline3}
                            exportOptions={{pdf: true, xlsx: true, csv: false}}
                            getExportFileName={() => 'rcx_daily_discipline_revenue_detail'}
                            isSortDisabled={true}
                        />
                    </TableStyle>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    <TableStyle>
                        <TableReport
                            columns={getTableColumns('Rotary')}
                            data={tableDataDiscipline4}
                            exportOptions={{pdf: true, xlsx: true, csv: false}}
                            getExportFileName={() => 'rotary_daily_discipline_revenue_detail'}
                            isSortDisabled={true}
                        />
                    </TableStyle>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    <TableStyle>
                        <TableReport
                            columns={getTableColumns('Total')}
                            data={tableTotalData}
                            exportOptions={{pdf: true, xlsx: true, csv: false}}
                            getExportFileName={() => 'total_daily_discipline_revenue_detail'}
                            isSortDisabled={true}
                        />
                    </TableStyle>
                </div>
            </div>
        </div>
    );
};

export default ReportBody;
