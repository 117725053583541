import React, {useState} from 'react';
import TableReport from "../../../common/table/table";
import FieldDailiesTable from "./fieldDailiesTable";
import MessageEmptyData from "../../../common/messageEmptyData";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import * as ArrayHelpers from "../../../../utils/arrayHelpers";
import {useAuthenticate} from "../../../../hooks/auth";

/**
 * Body component for ActiveFieldDailiesBody
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const FieldDailiesBody = (prop) => {
    const {filters, setFilters, tableData, setTableData, selectedRow, setSelectedRow, tableDataDisciplineId} = prop;
    const [deactivateModal, setDeactivateModal] = useState({isOpen: false, message: <p></p>});
    const [approveModal, setApproveModal] = useState({isOpen: false, message: <p></p>});
    const [disapproveModal, setDisapproveModal] = useState({isOpen: false, message: <p></p>});
    const [importModal, setImportModal] = useState({isOpen: false, message: <p></p>});
    const [reactivateModal, setReactivateModal] = useState({isOpen: false, message: <p></p>});
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});
    const { user } = useAuthenticate();

    const DeactivateHandler = () => {
        apiClient
            .post('deactivateFieldDaily', {'id': selectedRow.field_daily_id})
            .then(response => {
                const newTableData = ArrayHelpers.removeObjFromArray(tableData, "field_daily_id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDeactivateModal({...deactivateModal, isOpen: false})
            });
    }

    const ApproveHandler = () => {
        apiClient
            .post('approveFieldDailyW', selectedRow)
            .then(response => {
                const newTableData = ArrayHelpers.replaceObjFromArray(tableData, "field_daily_id", response.data.id, response.data.row);
                setTableData(newTableData);

                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setApproveModal({...approveModal, isOpen: false})
            });
    }

    const DisapproveHandler = () => {
        apiClient
            .post('disapproveFieldDailyW', selectedRow)
            .then(response => {
                const newTableData = ArrayHelpers.replaceObjFromArray(tableData, "field_daily_id", response.data.id, response.data.row);
                setTableData(newTableData);

                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDisapproveModal({...disapproveModal, isOpen: false})
            });
    }

    const ImportHandler = () => {
        const hole_id = selectedRow.hole_id;

        apiClient
            .post('importDailyW', selectedRow)
            .then(response => {
                const newTableData = ArrayHelpers.replaceObjFromArray(
                    tableData, "field_daily_id", response.data.id, response.data.row
                );
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setImportModal({...importModal, isOpen: false})
            });
    }

    const ReactivateHandler = () => {
        apiClient
            .post('activateFieldDaily', {'id': selectedRow.field_daily_id})
            .then(response => {
                const newTableData = ArrayHelpers.removeObjFromArray(tableData, "field_daily_id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setReactivateModal({...reactivateModal, isOpen: false})
            });
    }

    const DeleteHandler = () => {
        apiClient
            .post('deleteFieldDaily', {'field_daily_id': selectedRow.field_daily_id})
            .then(response => {
                const newTableData = ArrayHelpers.removeObjFromArray(tableData, "field_daily_id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid col-md-18">
            <TableReport
                columns={
                    FieldDailiesTable(
                        setFilters,
                        setSelectedRow,
                        setApproveModal,
                        setDisapproveModal,
                        setImportModal,
                        setDeactivateModal,
                        setReactivateModal,
                        setDeleteModal,
                        tableDataDisciplineId,
                        user
                    )
                }
                data={tableData}
                exportOptions={{pdf: false, xlsx: true, csv: true}}
                getExportFileName={() => 'field_dailies'}
                withGlobalSearch={true}
                scrollable={true}
            />
            <ModalConfirmation
                modal={deactivateModal}
                setModal={setDeactivateModal}
                title="Deactivate Field Daily"
                onConfirm={DeactivateHandler}
            />
            <ModalConfirmation
                modal={approveModal}
                setModal={setApproveModal}
                title="Approve Field Daily"
                onConfirm={ApproveHandler}
            />
            <ModalConfirmation
                modal={disapproveModal}
                setModal={setDisapproveModal}
                title="Disapprove Field Daily"
                onConfirm={DisapproveHandler}
            />
            <ModalConfirmation
                modal={importModal}
                setModal={setImportModal}
                title={(selectedRow.imported_by > 0) ? 'Imported Field Daily' : 'Import Field Daily'}
                onConfirm={ImportHandler}
            />
            <ModalConfirmation
                modal={reactivateModal}
                setModal={setReactivateModal}
                title="Reactivate Field Daily"
                onConfirm={ReactivateHandler}
            />
            <ModalConfirmation
                modal={deleteModal}
                setModal={setDeleteModal}
                title="Delete Field Daily"
                onConfirm={DeleteHandler}
            />
        </div>
    );
}

export default FieldDailiesBody;
