import React, { useEffect, useMemo } from 'react';
import ReactSelect from 'react-select'
import {Controller} from "react-hook-form";
import {get, isFunction, isNil} from "lodash";

/**
 * Searchable Dropdown
 * ----
 * Options: Array of options that populate the select menu
 * options = [
 *              { value: 'chocolate', label: 'Chocolate' },
 *              { value: 'strawberry', label: 'Strawberry' },
 *              { value: 'vanilla', label: 'Vanilla' }
 *            ]
 * -----
 * rules: Validation rules in the same format as for register.
 * rules={{ required: "State is required" }}
 * -----
 * control:(required) control object is from invoking useForm
 * -----
 * onChange: function that receive the selected value and label or null
 *   const onChange = (v) => {
 *       console.log(`option value selected:`, v);
 *   };
 *
 *   const onChange = (v, l) => {
 *     console.log(`option value selected:`, v);
 *     console.log(`option label selected:`, l);
 *   };
 *
 *   if you want to pass extract parameters to onChange,
 *   you can use the followed definition
 *   onChange={ (v,l) => otherFunction(v, l, param1, param2, ...)}
 * -----
 * value: number that represent the pre-selected value
 *    value={1}
 * -----
 * customStyles:
 * see https://react-select.com/styles#styles
 *
 * Full example:
 *
 *      <FormSearchableSelect
 *          id={`direct_entries[${index}].state_id`}
 *          options={states}
 *          value={1}
 *          className="col-md-2"
 *          rules={{ required: "State is required" }}
 *          control={control}
 *          isDisabled={false}
 *          errors={errors}
 *          onChange={onChange}
 *      />
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormSearchableSelect = (props) => {
    const {id, label, value, options, className, rules, errors, control, onChange, isDisabled, customStyles} = props;

    const defaultValue = useMemo(() => {
        return value ? options.find(item => item.value === value) : null;
    }, [options, value]);

    useEffect(() => {
        if (id) {
            control.setValue(id, defaultValue)
        }
    }, [control, defaultValue, id])

    return (
        <div className={className}>
            { label && <label htmlFor={id}>{label}</label>}
            <Controller
                name={id}
                control={control}
                rules={rules}
                defaultValue={defaultValue}
                render={ props =>
                    <ReactSelect
                        isClearable
                        name={props.name}
                        options={options}
                        value={props.value}
                        styles={customStyles}
                        isDisabled={isDisabled}
                        onChange={(e) => {
                            props.onChange(e);
                            if (isFunction(onChange)) {
                                if (isNil(e)) {
                                    onChange(e);
                                } else {
                                    onChange(e.value, e.label);
                                }
                            }
                        }}
                    />
                }
            />
            {errors && <span className="font-danger">{ get(errors, id)?.message }</span>}
        </div>
    );
}

export default React.memo(FormSearchableSelect);
