import {AccordionItem} from "react-light-accordion";
import React, {useCallback, useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import {useFieldArray} from "react-hook-form";
import SearchableSelectInTable from "../../../../forms/form-control/daily/SearchableSelectInTable";
import {getHours} from "../../../../../utils/listHelpers";
import * as Shared from "../../../../common/shared";
import apiClient from "../../../../../utils/apiClient";
import {isEmpty} from "lodash";
import FormInputText from "../../../../forms/form-control/FormInputText";
import FormSelectInTable from "../../../../forms/form-control/FormSelectInTable";

const GeneralActivities = (props) => {
    const {
        errors, register, control, collectedHoles, activityDescriptions, setWarningModal, selectedJobId,
        fieldDaily, action, disciplineId, setValue, totalHours, setTotalHours,
    } = props;
    const {fields, append, remove} = useFieldArray({
        control,
        name: "activities"
    });
    const [totalBillable, setTotalBillable] = useState(0);
    const [selectedActivityHole, setSelectedActivityHole] = useState('');

    const calculateGeneralActivitiesTotals = useCallback((setTotalHours, setTotalBillable) => {
        if (setTotalBillable) {
            Shared.calculateTotals('total_general_activities_hours', setTotalHours);
        }

        if (setTotalBillable) {
            Shared.calculateTotals('general_activities_total_billable', setTotalBillable);
        }
    }, []);

    useEffect(() => {
        if (!fields.length) {
            setTotalHours(0);
        }
    }, [fields.length, setTotalHours])

    useEffect(() => {
        if (action === 'edit') {
            append(fieldDaily.field_activities);

            setTimeout(() => {
                fieldDaily.field_activities.forEach((item, index) => {
                    // populate select
                    setValue(
                        `activities[${index}].cost_code`,
                        {value: item.cost_code, label: item.description}
                    );
                });
            }, 500);

            setTimeout(() => {
                calculateGeneralActivitiesTotals(setTotalHours, setTotalBillable);
            }, 500);
        }
    }, [fieldDaily, append, action, setValue, calculateGeneralActivitiesTotals, setTotalHours]);

    const calculateTotalHoursByStartFinishHours = (start, finish) => {
        if (start === finish) {
            return 0;
        }

        if (start < finish) {
            return Number(finish) - Number(start);
        }

        if (start > finish) {
            return (24 - Number(start)) + Number(finish);
        }
    }

    const calculateTotalHoursInRow = (e, index) => {
        if ([1, 2].includes(disciplineId)) {
            const priceCode = document.getElementById(`activities[${index}].price_code`);
            const billable = document.getElementById(`activities[${index}].billable`);

            if (isEmpty(priceCode.value) || parseInt(priceCode.value) === 0) {
                billable.value = '0.00';
            } else {
                billable.value = e.target.value;
            }

            if (e.target.value > 12) {
                const msg = 'Hours is higher than 12 hours';
                setWarningModal({
                    message: msg,
                    isOpen: true,
                });
            }
            calculateGeneralActivitiesTotals(setTotalHours, setTotalBillable);
        }

        if ([3, 4].includes(disciplineId)) {
            // get start & finish hours
            let startHours = (document.getElementById(`activities[${index}].start_hours`) !== null)
                ?
                    document.getElementById(`activities[${index}].start_hours`).value
                :
                    '';

            let finishHours = (document.getElementById(`activities[${index}].finish_hours`) !== null)
                ?
                    document.getElementById(`activities[${index}].finish_hours`).value
                :
                    '';

            if (!isEmpty(startHours) && !isEmpty(finishHours)) {
                // calculate total in row
                const totalRow = Number(
                    calculateTotalHoursByStartFinishHours(parseFloat(startHours), parseFloat(finishHours))
                ).toFixed(2);

                // display warning alert about total in row hours
                if (totalRow > 12 && totalRow !== document.getElementById(`activities[${index}].total_hours`).value) {
                    const msg = 'Total Hours is higher than 12 hours';
                    setWarningModal({
                        message: msg,
                        isOpen: true,
                    });
                }

                // display total in row hours
                document.getElementById(`activities[${index}].total_hours`).value = totalRow;

                // display billable hours
                const billable = document.getElementById(`activities[${index}].billable`);
                const priceCode = document.getElementById(`activities[${index}].price_code`);

                if (isEmpty(priceCode.value) || parseInt(priceCode.value) === 0) {
                    billable.value = "0.00";
                    billable.setAttribute("disabled", "disable");
                } else {
                    billable.removeAttribute("disabled");
                    billable.value = (totalRow > 0) ? totalRow : 0;
                }
            }
            calculateGeneralActivitiesTotals(setTotalHours, setTotalBillable);
        }
    }

    const removeActivitiesRow = (index) => {
        remove(index);
        //TODO: find a way to not use setTimeout
        setTimeout(() => {
            calculateGeneralActivitiesTotals(setTotalHours, setTotalBillable);
        }, 500);
    }

    const selectCostCodeRow = (index, value) => {
        //get activity record from list
        const activityDescription = activityDescriptions.find(obj => obj.value === value);
        const priceCode = document.getElementById(`activities[${index}].price_code`);
        const billable = document.getElementById(`activities[${index}].billable`);
        const totalHours = document.getElementById(`activities[${index}].total_hours`);

        //check if activity has revenue group mechanical loss
        if (activityDescription.revenue_group === 'ML') {
            priceCode.value = "0.00";
            billable.value = "0.00";
            billable.setAttribute("disabled", "disabled");
            return;
        }

        if (value && selectedJobId) {
            apiClient
                .post('getJobChargesPriceByCodeAndJobId', {'job_id': selectedJobId, 'code': value})
                .then(response => {
                    priceCode.value = response.data[0];
                    if (isEmpty(priceCode.value) || parseInt(priceCode.value) === 0) {
                        billable.value = "0.00";
                        billable.setAttribute("disabled", "disabled");
                    } else {
                        billable.removeAttribute("disabled");
                        billable.value = totalHours.value;
                    }
                    if ([1, 2].includes(disciplineId)) {
                        calculateGeneralActivitiesTotals(setTotalHours, setTotalBillable);
                    } else if ([3, 4].includes(disciplineId)) {
                        calculateTotalHoursInRow(index);
                    }
                });
        }
    }

    return (
        <AccordionItem className="card-header bg-primary" title="General Activities">
            <div className="card">
                { totalHours > 0 && fields.length > 0 &&
                    <div className="form-row">
                        <div className="col-md-3 mb-3 total_block">
                            <p className="form-control background_total_block_color">
                                Total Hours: {totalHours}
                            </p>
                        </div>
                        <div className="col-md-3 mb-3 total_block">
                            <p className="form-control background_total_block_color">
                                Total Billable: {totalBillable}
                            </p>
                        </div>
                    </div>
                }
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        {[1, 2].includes(disciplineId) &&
                                            <th>Hours</th>
                                        }
                                        {[3, 4].includes(disciplineId) &&
                                            <>
                                                <th>Start Hours</th>
                                                <th>Finish Hours</th>
                                                <th>Total Hours</th>
                                            </>
                                        }
                                        <th>Billable</th>
                                        <th>Hole</th>
                                        <th className="action-cell">
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={() => append({})}
                                            />
                                            <FaEraser
                                                color='#A51E37'
                                                className="marginLeft"
                                                size={20}
                                                title="Remove All"
                                                onClick={() => remove()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {fields.map((item, index) => {
                                    const lastFinishHours = (index === 0) ? null : fields[index-1].finish_hours;
                                    return (
                                        <tr key={item.id}>
                                            <SearchableSelectInTable
                                                id={`activities[${index}].cost_code`}
                                                options={activityDescriptions}
                                                rules={{ required: "required" }}
                                                control={control}
                                                value={(action === 'edit') ? item.cost_code?.value : ''}
                                                errors={errors}
                                                onChange={(value) => selectCostCodeRow(index, value)}
                                                customStyles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: 500,
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: 500,
                                                        position: 'sticky',
                                                    })
                                                }}
                                            />
                                            {[3, 4].includes(disciplineId) &&
                                                <>
                                                    <SelectInTable
                                                        className="short-select-width"
                                                        id={`activities[${index}].start_hours`}
                                                        name={`activities[${index}].start_hours`}
                                                        options={getHours()}
                                                        value={!isEmpty(item.start_hours) ? item.start_hours : lastFinishHours}
                                                        onChange={(e) => calculateTotalHoursInRow(e, index)}
                                                        register={register({required: "required"})}
                                                        errors={errors}
                                                    />
                                                    <SelectInTable
                                                        className="short-select-width"
                                                        id={`activities[${index}].finish_hours`}
                                                        name={`activities[${index}].finish_hours`}
                                                        options={getHours()}
                                                        value={(action === 'edit') ? item.finish_hours : ''}
                                                        onChange={(e) => calculateTotalHoursInRow(e, index)}
                                                        register={register({required: "required"})}
                                                        errors={errors}
                                                    />
                                                    <InputInTable
                                                        className="total_general_activities_hours short-select-width"
                                                        id={`activities[${index}].total_hours`}
                                                        value={(action === 'edit') ? item.total_hours : ''}
                                                        name={`activities[${index}].total_hours`}
                                                        register={register({required: false})}
                                                        disabled={'true'}
                                                    />
                                                </>
                                            }
                                            {[1, 2].includes(disciplineId) &&
                                                <SelectInTable
                                                    className="total_general_activities_hours short-select-width"
                                                    id={`activities[${index}].total_hours`}
                                                    name={`activities[${index}].total_hours`}
                                                    options={getHours()}
                                                    value={(action === 'edit') ? item.total_hours : ''}
                                                    onChange={(e) => calculateTotalHoursInRow(e, index)}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            <FormSelectInTable
                                                className="general_activities_total_billable short-select-width"
                                                id={`activities[${index}].billable`}
                                                name={`activities[${index}].billable`}
                                                options={getHours()}
                                                value={
                                                    (action === 'edit')
                                                        ?
                                                        (item.billable !== null)
                                                            ?
                                                            item.billable
                                                            :
                                                            "0.00"
                                                        :
                                                        ''
                                                }
                                                onChange={() => calculateGeneralActivitiesTotals(setTotalHours, setTotalBillable)}
                                                register={register({required: "required"})}
                                                disabled={
                                                    (action === 'edit')
                                                        ?
                                                            ( (parseInt(item.price) === 0) || isEmpty(item.price)) ? true : ''
                                                        :
                                                            ''
                                                }
                                                errors={errors}
                                            />
                                            {action === 'create' &&
                                                <SelectInTable
                                                    id={`activities[${index}].hole_name`}
                                                    name={`activities[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={(collectedHoles.length === 1) ? collectedHoles[0].value : ''}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            {action === 'edit' &&
                                                <SelectInTable
                                                    id={`activities[${index}].hole_name`}
                                                    name={`activities[${index}].hole_name`}
                                                    value={
                                                        selectedActivityHole
                                                            ?
                                                            selectedActivityHole
                                                            :
                                                            (collectedHoles.length === 1)
                                                                ?
                                                                collectedHoles[0].value
                                                                :
                                                                item.hole_name
                                                    }
                                                    onChange={
                                                        (e) => Shared.setSelectedValue(e.target.value, setSelectedActivityHole)
                                                    }
                                                    options={collectedHoles}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            <td>
                                                <FormInputText
                                                    id={`activities[${index}].price_code`}
                                                    type="hidden"
                                                    value={(action === 'edit') ? item.price : ''}
                                                    register={register}
                                                />
                                                <FaTrash
                                                    color='#A51E37'
                                                    size={20}
                                                    title="Remove"
                                                    onClick={() => removeActivitiesRow(index)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default GeneralActivities;