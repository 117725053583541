import React from 'react';
import {Link} from 'react-router-dom';
import exportToHTML from "../../../../utils/exportToHtml";
import { formatNumberToDisplayWithCommas } from '../../../common/shared';

export const transactionRegisterPlusTable = [
    {
        Header: 'Date',
        accessor: 'date',
        width: 80,
    },
    {
        Header: 'Rig',
        accessor: 'rig',
        width: 60,
    },
    {
        Header: 'Shift',
        accessor: 'daily_shift',
        width: 60,
    },
    {
        Header: 'Revenue',
        accessor: 'revenue',
        width: 80,
        Cell: (cell) => formatNumberToDisplayWithCommas(cell.value),
    },
    {
        Header: 'Client',
        accessor: 'client',
    },
    {
        Header: 'Job #',
        accessor: 'job_number',
        width: 90,
    },

    {
        Header: 'Location Name',
        accessor: 'location',
    },
    {
        Header: 'Hole',
        accessor: 'hole',
        width: 100,
    },
    {
        Header: 'Status',
        accessor: 'invoice_status',
        width: 70,
    },
    {
        Header: 'Invoice #',
        accessor: 'invoice_number',
        width: 90,
        Cell: (cell) => {
            const invoiceId = cell.row.original.invoice_id;
            if (!invoiceId) {
                return null;
            }
            return <Link to={{}} onClick={() => exportToHTML('exportInvoiceById', invoiceId)}>{cell.value}</Link>
        }
    }
];
