import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import LoadingIndicator from "../../../common/loadingIndicator";
import JobCostTrackingExportFilters from './jobCostTrackingExportFilters';

/**
 * Job Cost Tracking Export
 * @returns {JSX.Element}
 * @constructor
 */
const JobCostTrackingExport = () => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <Breadcrumb title="Job Cost Tracking Export" parent="Toolbox"/>
            <JobCostTrackingExportFilters setIsLoading={setIsLoading} />
            <LoadingIndicator isLoading={isLoading}/>
        </>
    );
}

export default JobCostTrackingExport;
