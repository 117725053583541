/**
 * This is a global helper methods that are shared between Create/Edit Daily components
 */

// to set selected value of dropdown
export const setSelectedValue = (value, setter) => {
    setter(value);
}

// just a custom setter into the state
export const setArrayIntoState = (counter, number, rows, row, setAddedRow, addedRow, setSection) => {
    if (counter === number) {
        rows.push(row);
        setAddedRow(rows);

        if (addedRow) {
            addedRow.push(row);
        }

        setSection(addedRow);
    }
}

// unset custom setters
export const unsetSetters = (firstSetter, secondSetter) => {
    firstSetter([]);
    secondSetter([]);
}

// limit input with type number value
// google chrome ignores maxLength property for type number
export const limitInput = (input) => {
    if (input.value.length > input.maxLength) {
        input.value = input.value.slice(0, input.maxLength);
    }
}

// limit input with type number value
// google chrome ignores maxLength property for type number
export const limitInputDaily = (input, max) => {
    if (input.value.length > max) {
        input.value = input.value.slice(0, max);
    }
}

// limit input with type number value
// google chrome ignores maxLength property for type number
export const inputDaily3Digits = (input) => {
    input.value = Number(input.value).toFixed(3);
}

/*
 * calculating sum for totals in card
 * @ className - name of class that wraps total div
 * @ setter - state setter that will keep total value
 */
export const calculateTotals = (className, setter) => {
    let totalRaw = [];
    let result = 0;
    totalRaw.push(document.getElementsByClassName(className));
    let count = totalRaw[0].length;
    let i;

    if (count > 0) {
        for (i = 0; i <= count; i++) {
            if (typeof totalRaw[0][i] !== 'undefined' && totalRaw[0][i].value ) {
                result += Number(parseFloat(totalRaw[0][i].value).toFixed(2));
            }
        }

        setter(Number(result).toFixed(2));
    }

    return result;
}

/*
 * calculating sum for totals in cards
 * @ className - name of class that wraps total div
 * @ setter - state setter that will keep total value
 */
export const calculateTotalsInCards = (fields, setter) => {
    let total = 0;
    fields.forEach((column) => {
        if (typeof column.total !== 'undefined') {
            total += Number(column.total);
        }
    });

    setter(total);
}

/*
 * calculating total variance for footage drilled and reamed cards
 * @ className - name of class that wraps total div
 * @ setter - state setter that will keep total value
 */
export const calculateTotalVariance = (drilledClass, billableClass, totalPriceClass, setter) => {
    let totalDrilledRaw = [];
    let totalBillableRaw = [];
    let totalPriceRaw = [];
    let variance = 0;
    totalDrilledRaw.push(document.getElementsByClassName(drilledClass));
    totalBillableRaw.push(document.getElementsByClassName(billableClass));
    totalPriceRaw.push(document.getElementsByClassName(totalPriceClass));

    let countDrilled = totalDrilledRaw[0].length;
    let countBillable = totalBillableRaw[0].length;
    let i;

    if (countDrilled > 0 && countBillable > 0) {
        for (i = 0; i <= countDrilled; i++) {
            if (typeof totalDrilledRaw[0][i] !== 'undefined' && totalDrilledRaw[0][i].value ) {
                if (typeof totalBillableRaw[0][i] !== 'undefined' && totalBillableRaw[0][i].value ) {
                    // TODO: clarify with Sophie
                    // if (typeof totalPriceRaw[0][i] !== 'undefined' && totalPriceRaw[0][i].value > 0 ) {
                        variance += Number(totalDrilledRaw[0][i].value) - Number(totalBillableRaw[0][i].value);
                    // }
                }
            }
        }

        setter(Number(variance).toFixed(2));
    } else {
        setter(Number(0).toFixed(2));
    }
}

/*
 * Works with react-images-upload component
 * Uploading base64 encoded image file for field daily and for daily signatures and fuel receipts
 * @ picture - image from file picker
 * @ name - html hidden field name to which we assign value to pass with form
 * @ hiddenOriginalFileName - original name of the file
 * @ setValue - setter of the useForm hook
 */
export const UploadBase64EncodedImageFile = (
    picture, name, hiddenOriginalFileName, typeId, gallonsId, suppliedById, holeNameId, setValue
) => {
    let type = (document.getElementById(typeId).value) ? document.getElementById(typeId).value : '';
    let gallons = (document.getElementById(gallonsId).value) ? Number(document.getElementById(gallonsId).value).toFixed(2) : '';
    let suppliedBy = (document.getElementById(suppliedById).value) ? document.getElementById(suppliedById).value : '';
    let holeName = (document.getElementById(holeNameId).value) ? document.getElementById(holeNameId).value : '';

    // base64 encode image to be stored by API endpoint
    let reader = new FileReader();
    // we need original file name attached to reader
    if (picture[0]) {
        reader.fileName = type + '&' + gallons + '&' + suppliedBy + '&' + holeName + '&' + picture[0].name;
        reader.readAsDataURL(picture[0]);

        reader.onloadend = function() {
            // this is ugly, but I don't see any other way
            // we can't control that image picker component as we can input text
            // store base64 string
            setValue(name, reader.result);
            // store original file name
            setValue(hiddenOriginalFileName, reader.fileName);
        }
    }
}

export const calculateTotalRevenue = (
    totalActivities, totalConsumables, totalGeneralCharges, totalReamedPrice, totalDrilledPrice
) => {
    let result = Number(totalActivities) + Number(totalConsumables) + Number(totalGeneralCharges) +
        Number(totalDrilledPrice) + Number(totalReamedPrice);

    return Number(result).toLocaleString('en-US', {style: 'currency', currency: 'USD'});
}

export const calculateDrilledRevenue = (totalDrilledPrice, totalReamedPrice) => {
    let result = Number(totalDrilledPrice) + Number(totalReamedPrice);
    return Number(result).toLocaleString('en-US', {style: 'currency', currency: 'USD'});
}

export const parseMoneyField = (field) => {
    return parseFloat(field.replace(/[,$]/g, ''));
}

export function sortNumericStringColumn(row1, row2, columnId) {
    const value1 = row1.values[columnId]?.replace(/\D/g,'');
    const value2 = row2.values[columnId]?.replace(/\D/g,'');

    if (value1 == null) {
        return -1;
    } else if (value2 == null) {
        return 1;
    }

    if (!isNaN(Number(value1)) && !isNaN(Number(value2))) {
        return Number(value1) - Number(value2);
    }

    return value1.localeCompare(value2);
}

export const calculateTotalFootage = (disciplineId, totalFootageDrilled, totalFootageDrilledDepth) => {
    let result;
    if (['3', '4'].includes(disciplineId)) {
        result = Number(totalFootageDrilled).toFixed(2);
    } else if (['1', '2'].includes(disciplineId)) {
        result = Number(totalFootageDrilledDepth).toFixed(2);
    }

    return result;
}

// collect holes into dropdown
export const buildHoles = (e, disciplineId, setter) => {
    e.preventDefault();

    if (disciplineId > 0) {
        let holesRaw = [];
        let holes = [];
        let classNameString = [1, 2].includes(disciplineId) ? 'hole_core' : 'hole_rotary';
        holesRaw.push(document.getElementsByClassName(classNameString));
        let count = holesRaw[0].length;

        let i;
        for (i = 0; i <= count; i++) {
            if (typeof holesRaw[0][i] !== 'undefined') {
                holes.push(holesRaw[0][i].value);
            }
        }

        let options = holes.map((value) => ({value: value, label: value}));
        setter(options);
    }
}

// collect holes into dropdown in field daily
export const buildFieldDailyHoles = (v, setValue, name, getValues, disciplineId, setCollectedHoles) => {
    // reserve to be submitted with the form
    setValue(name, {value: v, label: v});
    let dataRaw = getValues();
    let data = [1, 2].includes(disciplineId) ? dataRaw.hole_core : dataRaw.hole_rotary;
    let options = [];

    data.forEach((value) => {
        if (typeof value?.name !== 'undefined') {
            options.push({value: value.name?.value, label: value.name?.value});
        }
    });

    setCollectedHoles(options);
}

// collect holes into dropdown
export const buildHolesDaily = (v, name, setterPerDiscipline, holesSetter, setValue, disciplineId, collectedHoles) => {
    // reserve to be submitted with the form
    setValue(name, {value: v.value, label: v.label});
    if (disciplineId) {
        setSelectedValue(v.value, setterPerDiscipline);

        let holesArray = [v];
        let holes = holesArray.map(({value, label}) =>
            ({value: value, label: label})
        )

        if (typeof collectedHoles !== 'undefined' && collectedHoles.length > 0) {
            collectedHoles.push(holes[0]);
            holesSetter(collectedHoles);
        } else {
            holesSetter(holes);
        }
    }
}

// toggle all accordion items
export const toggleAllAccordionItems = () => {
    const accordionItems = [];
    // populate all accordion items into an array
    accordionItems.push(document.getElementsByClassName("accordion-item"));
    // iterate over accordionItems to find titles
    let i;
    for (i = 0; i < accordionItems[0].length; i++) {
        accordionItems[0][i].classList.add("active");
    }
}

/*
 * style for ReactSelect
 */
export const customStyles = {
    control:  (provided)=> ({
        ...provided,
         width: 310,
        // position: 'relative',
        // top: 40,
        // height: 25,
        // minHeight: 10,
        // overflow:'hidden'
    }),
    menu: (provided) => ({
        ...provided,
        // width: 210,
        position: 'sticky',
        top: 40
    }),
    menulist: (provided) => ({
        ...provided,
        // width: 210,
    }),
    option: (provided) => ({
        ...provided,
        // width: 210,
        height: 24,
        minHeight: 15,
        paddingTop:0,
    }),
    placeholder: (provided) => ({
        ...provided,
        // color:
        // fontWeight: 400,
        // position: 'relative',
        // top: 2,
        overflow:'hidden'
    })
}

/*
 * style for ReactSelect wide
 */
export const customStylesWide = {
    control:  (provided)=> ({
        ...provided,
        width: 450,
        // position: 'relative',
        // top: 40,
        // height: 25,
        // minHeight: 10,
        // overflow:'hidden'
    }),
    menu: (provided) => ({
        ...provided,
        // width: 210,
        position: 'sticky',
        top: 40
    }),
    menulist: (provided) => ({
        ...provided,
        // width: 210,
    }),
    option: (provided) => ({
        ...provided,
        // width: 210,
        height: 24,
        minHeight: 15,
        paddingTop:0,
    }),
    placeholder: (provided) => ({
        ...provided,
        // color:
        // fontWeight: 400,
        // position: 'relative',
        // top: 2,
        overflow:'hidden'
    })
}

export const customStylesSmall = {
    control:  (provided)=> ({
        ...provided,
        width: 250,
        // position: 'relative',
        // top: 40,
        // height: 25,
        // minHeight: 10,
        // overflow:'hidden'
    }),
    menu: (provided) => ({
        ...provided,
        width: 250,
        position: 'sticky',
        top: 40
    }),
    menulist: (provided) => ({
        ...provided,
        width: 250,
    }),
    option: (provided) => ({
        ...provided,
        width: 250,
        height: 24,
        minHeight: 15,
        paddingTop:0,
    }),
    placeholder: (provided) => ({
        ...provided,
        // color:
        // fontWeight: 400,
        // position: 'relative',
        // top: 2,
        overflow:'hidden'
    })
}

export const customStylesSmall_120 = {
    control:  (provided)=> ({
        ...provided,
        width: 120,
    }),
    menu: (provided) => ({
        ...provided,
        width: 120,
        position: 'sticky',
        top: 40
    }),
    menulist: (provided) => ({
        ...provided,
        width: 120,
    }),
    option: (provided) => ({
        ...provided,
        width: 120,
        height: 24,
        minHeight: 15,
        paddingTop:0,
    }),
    placeholder: (provided) => ({
        ...provided,
        overflow:'hidden'
    })
}

export const formatNumberToDisplayWithCommas = (number) =>
{
    let numberString = Number(number).toFixed(2);
    let parts = numberString.split('.');
    let integerPart = parts[0].padStart(2, '0');
    let integerPartWithCommas = Number(integerPart).toLocaleString();
    let result = integerPartWithCommas + '.' + (parts[1] ? parts[1] : '00');
    return result;
}