import React from 'react';
import TableReport from "../../../common/table/table";
import MessageEmptyData from "../../../common/messageEmptyData";
import {isEmpty} from 'lodash';
import {TableStyle} from "./tableStyle";

/**
 * Body report component for revenue by rig weekly report
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ReportBody = (prop) => {
    const {
        reportFilters,
        tableHeaderDiscipline1,
        tableHeaderDiscipline2,
        tableHeaderDiscipline3,
        tableHeaderDiscipline4,
        tableRowsDiscipline1,
        tableRowsDiscipline2,
        tableRowsDiscipline3,
        tableRowsDiscipline4,
        totalTableHeader,
        totalTableRows
    } = prop;

    if (!reportFilters.isSubmitted) {
        return null;
    }

    if (isEmpty(tableHeaderDiscipline1) &&
        isEmpty(tableHeaderDiscipline2) &&
        isEmpty(tableHeaderDiscipline3) &&
        isEmpty(tableHeaderDiscipline4) &&
        isEmpty(tableRowsDiscipline1) &&
        isEmpty(tableRowsDiscipline2) &&
        isEmpty(tableRowsDiscipline3) &&
        isEmpty(tableRowsDiscipline4)) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid overflow">
            <div className="row">
                <div className="col">
                    <h5>Surface Core</h5>
                </div>
            </div>
            <TableStyle>
                <TableReport
                    columns={tableHeaderDiscipline1}
                    data={tableRowsDiscipline1}
                    exportOptions={{pdf: false, xlsx: true, csv: false}}
                    getExportFileName={() => 'surface_core_revenue_by_rig_weekly'}
                    withGlobalSearch={false}
                    isSortDisabled={true}
                />
            </TableStyle>
            <div className="row">
                <div className="col">
                    <h5>Underground Core</h5>
                </div>
            </div>
            <TableStyle>
                <TableReport
                    columns={tableHeaderDiscipline2}
                    data={tableRowsDiscipline2}
                    exportOptions={{pdf: false, xlsx: true, csv: false}}
                    getExportFileName={() => 'underground_core_revenue_by_rig_weekly'}
                    withGlobalSearch={false}
                    isSortDisabled={true}
                />
            </TableStyle>
            <div className="row">
                <div className="col">
                    <h5>RCX</h5>
                </div>
            </div>
            <TableStyle>
                <TableReport
                    columns={tableHeaderDiscipline3}
                    data={tableRowsDiscipline3}
                    exportOptions={{pdf: false, xlsx: true, csv: false}}
                    getExportFileName={() => 'rcx_revenue_by_rig_weekly'}
                    withGlobalSearch={false}
                    isSortDisabled={true}
                />
            </TableStyle>
            <div className="row">
                <div className="col">
                    <h5>Rotary</h5>
                </div>
            </div>
            <TableStyle>
                <TableReport
                    columns={tableHeaderDiscipline4}
                    data={tableRowsDiscipline4}
                    exportOptions={{pdf: false, xlsx: true, csv: false}}
                    getExportFileName={() => 'rotary_revenue_by_rig_weekly'}
                    withGlobalSearch={false}
                    isSortDisabled={true}
                />
            </TableStyle>
            <div className="row">
                <div className="col">
                    <h5>Total</h5>
                </div>
            </div>
            <TableStyle>
                <TableReport
                    columns={totalTableHeader}
                    data={totalTableRows}
                    exportOptions={{pdf: false, xlsx: true, csv: false}}
                    getExportFileName={() => 'total_revenue_by_rig_weekly'}
                    withGlobalSearch={false}
                    isSortDisabled={true}
                />
            </TableStyle>
        </div>
    );
};

export default ReportBody;
