import React, { useMemo } from 'react';
import FormSelect from "../../../forms/form-control/FormSelect";
import FormInputDate from "../../../forms/form-control/FormInputDate";
import FormInputText from "../../../forms/form-control/FormInputText";
import {FaPlus, FaTrash} from "react-icons/fa";
import moment from "moment";
import { BREAKPOINTS } from '../../../../utils/constants';
import FormInputNumber from "../../../forms/form-control/FormInputNumber";
import * as Shared from "../../../common/shared";
import { useAuthenticate } from '../../../../hooks/auth';
import FormSearchableSelect from '../../../forms/form-control/FormSearchableSelect';

/**
 * CreateOfficeEntryTable Table Component
 * @returns {JSX.Element}
 * @constructor
 */
const CreateOfficeEntryTable = (props) => {
    const {register, errors, fields, append, remove, ecOptions, payPeriod, users, control} = props;
    const { user: currentUser } = useAuthenticate();

    const isAdministrator = currentUser?.group_id === 1;

    const employeeFieldValue = useMemo(() => {
        const defaultUser = users.find(userOption => userOption.value === currentUser.id);

        return defaultUser && isAdministrator ? defaultUser.value : null;
    }, [users, isAdministrator, currentUser])

    if (window.innerWidth <= BREAKPOINTS['lg']) {
        return (
            <div className="s-flex s-flex-col s-flex-gy">
                {fields.map((f, index) => (
                    <div key={f.id} className="form-row s-gy">
                         {isAdministrator && 
                            <FormSearchableSelect
                                label="Employee"
                                value={employeeFieldValue}
                                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                id={`time_entries[${index}].user_id`}
                                options={users}
                                rules={{ required: "required" }}
                                control={control}
                                errors={errors}
                            />
                        }
                        <FormInputDate
                            id={`time_entries[${index}].date`}
                            label="Work Date"
                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                            register={register({required: "required"})}
                            min={moment(new Date(payPeriod.start_date)).subtract(7,'d').format('YYYY-MM-DD')}
                            max={moment(new Date(payPeriod.end_date)).format('YYYY-MM-DD')}
                            errors={errors}
                        />
                        <FormSelect
                            id={`time_entries[${index}].ec_id`}
                            label="Reason"
                            options={ecOptions}
                            register={register({required: "required"})}
                            errors={errors}
                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                        />
                        <FormInputNumber
                            label="Hours"
                            id={`time_entries[${index}].hours`}
                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                            register={register({
                                required: {value: true, message: 'required'},
                                min: {value: 0, message: "min value is 0"},
                                max: {value: 24.01, message: "max value is 24.00"}
                            })}
                            onInput={(e) => Shared.limitInputDaily(e.target, 5)}
                            errors={errors}
                        />
                        <FormInputText
                            id={`time_entries[${index}].notes`}
                            label="Notes"
                            className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                            register={register}
                            errors={errors}
                        />

                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2 s-flex s-justify-between">
                            <FaPlus
                                color='#22af47'
                                size={20}
                                title="Add"
                                onClick={() => append({})}
                            />
                            <FaTrash
                                color='#A51E37'
                                size={20}
                                title="Remove"
                                onClick={() => remove(index)}
                            />
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <>
            <div className="form-row">
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                        {isAdministrator && <th>Employee</th>}
                        <th>Work Date</th>
                        <th>Reason</th>
                        <th>Hours</th>
                        <th>Notes</th>
                        <th>
                            <FaPlus
                                color='#22af47'
                                size={20}
                                title="Add"
                                onClick={() => append({})}
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((item, index) => (
                        <tr key={item.id}>
                            {isAdministrator &&
                                <td className="col-2">
                                    <FormSearchableSelect
                                        value={employeeFieldValue}
                                        id={`time_entries[${index}].user_id`}
                                        options={users}
                                        rules={{ required: "required" }}
                                        control={control}
                                        errors={errors}
                                    />
                                </td>
                            }
                            <td>
                                <FormInputDate
                                    id={`time_entries[${index}].date`}
                                    register={register({required: "required"})}
                                    min={moment(new Date(payPeriod.start_date)).subtract(7, 'd').format('YYYY-MM-DD')}
                                    max={moment(new Date(payPeriod.end_date)).format('YYYY-MM-DD')}
                                    errors={errors}
                                />
                            </td>
                            <td>
                                <FormSelect
                                    id={`time_entries[${index}].ec_id`}
                                    options={ecOptions}
                                    register={register({required: "required"})}
                                    errors={errors}
                                />
                            </td>
                            <td>
                                <FormInputNumber
                                    id={`time_entries[${index}].hours`}
                                    register={register({
                                        required: {value: true, message: 'required'},
                                        min: {value: 0, message: "min value is 0"},
                                        max: {value: 24.01, message: "max value is 24.00"}
                                    })}
                                    onInput={(e) => Shared.limitInputDaily(e.target, 5)}
                                    errors={errors}
                                />
                            </td>
                            <td>
                                <FormInputText
                                    id={`time_entries[${index}].notes`}
                                    register={register}
                                    errors={errors}
                                />
                            </td>
                            <td>
                                <FaTrash
                                    color='#A51E37'
                                    size={20}
                                    title="Remove"
                                    onClick={() => remove(index)}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default CreateOfficeEntryTable;
