import { Fragment, useEffect, useMemo, useState } from "react";
import { useAsyncDebounce, useBlockLayout, useGlobalFilter, useSortBy, useTable } from "react-table";
import { useExportData } from "react-table-plugins";
import { useSticky } from "react-table-sticky";
import { isNull } from "lodash";
import getExportFileBlob from "../../../../utils/exportTable";
import TableGlobalFilter from "../table-scrollable/tableGlobalFilter";
import TableItem from "./TableItem";
import FormSelect from "../../../forms/form-control/FormSelect";
import TableExportButtons from "../table-scrollable/tableExportButtons";

const sortDirections = [
    { id: 'asc', value: 'asc', label: 'Ascending' },
    { id: 'desc', value: 'desc', label: 'Descending' },
];

const TableMobile = ({
    columns,
    data,
    exportOptions,
    getExportFileName,
    withGlobalSearch,
    isSortDisabled = false,
}) => {
    const {
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
        exportData,
    } = useTable(
        {
            columns,
            data,
            getExportFileBlob,
            getExportFileName,
            disableSortBy: isSortDisabled,
        },
        useGlobalFilter,
        useSortBy,
        useExportData,
        useBlockLayout,
        useSticky
    );

    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState(sortDirections[0]);
    const headers = Array.isArray(headerGroups) ? headerGroups[headerGroups.length - 1].headers || [] : [];

    const optionsOfSortBy = useMemo(() => {
        return headers.filter((h) => h.canSort).map((h) => ({
            id: h.id,
            value: h.id,
            label: h['Header'],
        }));
    }, [headers]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSelectSortBy = (e) => {
        setSortBy(optionsOfSortBy.find((o) => o.id === e.target.value));
    }

    const onSelectSortDirection = (e) => {
        setSortDirection(sortDirections.find((d) => d.value === e.target.value));
    }

    useEffect(() => {
        if (sortBy && sortDirection) {
            const header = headers.find((h) => h.id === sortBy.id);
            header.toggleSortBy(sortDirection.value === 'desc', [header.id]);
        }
    }, [sortBy, sortDirection, headers])

    return (
        <div className="s-table-mobile-container">
            {withGlobalSearch && (
                <TableGlobalFilter
                    filter={state.globalFilter}
                    setFilter={setGlobalFilter}
                    useAsyncDebounce={useAsyncDebounce}
                />
            )}
            <div className="s-sorters">
                <FormSelect
                    id="sortBy"
                    label="Sort By"
                    className="s-sorter"
                    value={sortBy}
                    onChange={onSelectSortBy}
                    options={optionsOfSortBy}
                />

                <FormSelect
                    id="sortDirection"
                    label="Sort Direction"
                    className="s-sorter"
                    value={sortDirection}
                    onChange={onSelectSortDirection}
                    options={sortDirections}
                />
            </div>
            <div className="s-table-mobile">
                {rows.map((r, i) => {
                    if (rows.length - 1 === i) {
                        return (
                            <TableItem
                                key={r.id}
                                row={r}
                                prepareRow={prepareRow}
                                headerGroups={headerGroups}
                            />
                        );
                    }

                    return (
                        <Fragment key={r.id}>
                            <TableItem
                                row={r}
                                prepareRow={prepareRow}
                                headerGroups={headerGroups}
                            />
                            <hr className="s-divider" />
                        </Fragment>
                    )
                })}
            </div>

            {!isNull(exportOptions) &&
                <TableExportButtons exportData={exportData} exportOptions={exportOptions} />
            }
        </div>
    )
}

export default TableMobile;