import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import JobChargesAccordion from "./jobChargesAccordion";
import {toast} from "react-toastify";
import LoadingIndicator from "../../../common/loadingIndicator";
import FormSelectDaily from "../../../forms/form-control/FormSelectDaily";
import CancelButton from "../../../forms/form-control/daily/CancelButton";
import SubmitButton from "../../../forms/form-control/daily/SubmitButton";
import {CSVReader} from "react-papaparse";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import {isEmpty} from "lodash";
import {addObjToArray} from "../../../../utils/arrayHelpers";

/**
 * JobCharges Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const JobCharges = (props) => {
    const action = props.match.params.action;
    const [displayFilters, setDisplayFilters] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [selectedJob, setSelectedJob] = useState('');
    const [disciplines, setDisciplines] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [rigActivitiesCostCodes, setRigActivitiesCostCodes] = useState([]);
    const [drillingCostCodes, setDrillingCostCodes] = useState([]);
    const [footageCostCodes, setFootageCostCodes] = useState([]);
    const [reamingCostCodes, setReamingCostCodes] = useState([]);
    const [surveyCostCodes, setSurveyCostCodes] = useState([]);
    const [standbyCostCodes, setStandbyCostCodes] = useState([]);
    const [rigMovesCostCodes, setRigMovesCostCodes] = useState([]);
    const [repairAndMaintenanceCostCodes, setRepairAndMaintenanceCostCodes] = useState([]);
    const [equipmentCostCodes, setEquipmentCostCodes] = useState([]);
    const [consumablesCostCodes, setConsumablesCostCodes] = useState([]);
    const [waterHaulCostCodes, setWaterHaulCostCodes] = useState([]);
    const [auxiliaryEquipmentCostCodes, setAuxiliaryEquipmentCostCodes] = useState([]);
    const [lumpSumsOtherCharges, setLumpSumsOtherCharges] = useState([]);
    const [generalWorkPerDiemLargeChargesCostCodes, setGeneralWorkPerDiemLargeChargesCostCodes] = useState([]);
    const buttonRef = React.createRef();
    const [isLoading, setIsLoading] = useState(false);
    const {register, control, handleSubmit, errors, reset, setValue, getValues} = useForm();
    const [repairAndMaintenanceSection, setRepairAndMaintenanceSection] = useState([]);
    const [rigActivitiesSection, setRigActivitiesSection] = useState([]);
    const [drillingSection, setDrillingSection] = useState([]);
    const [reamingSection, setReamingSection] = useState([]);
    const [generalWorkSection, setGeneralWorkSection] = useState([]);
    const [surveysSection, setSurveysSection] = useState([]);
    const [standbySection, setStandbySection] = useState([]);
    const [rigMovesSection, setRigMovesSection] = useState([]);
    const [lumpSumsSection, setLumpSumsSection] = useState([]);
    const [equipmentSection, setEquipmentSection] = useState([]);
    const [waterHaulSection, setWaterHaulSection] = useState([]);
    const [auxiliaryEquipmentSection, setAuxiliaryEquipmentSection] = useState([]);
    const [consumablesSection, setConsumablesSection] = useState([]);
    const [footageSection, setFootageSection] = useState([]);
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});

    // get list of options
    useEffect(() => {
        if (action === 'create') {
            setIsDataLoaded(true);
        }

        //get disciplines
        apiClient
            .get('getDisciplines')
            .then(response => {
                const disciplines = response.data.disciplines.map(
                    ({id, name}) => ({value: id, label: name})
                );

                setDisciplines(
                    addObjToArray(disciplines, {value: 99, label: 'All'})
                );
            });

        apiClient
            .get('getAllCostCodes')
            .then(response => {
                // repair and maintenance codes
                const repairAndMaintenanceRaw = response.data.map(({code, description}) =>
                    (code >= 1 && code <= 99 ? {value: code, label: code + ' ' + description} : '')
                );
                const repairAndMaintenance = repairAndMaintenanceRaw.filter(item => item !== '');
                setRepairAndMaintenanceCostCodes(repairAndMaintenance);

                // rigActivities codes
                const rigActivitiesRaw = response.data.map(({code, description}) =>
                    (code >= 200 && code <= 260 ? {value: code, label: code + ' ' + description} : '')
                );
                const rigActivities = rigActivitiesRaw.filter(item => item !== '');
                setRigActivitiesCostCodes(rigActivities);

                // Drilling codes
                const drillingCodesRaw = response.data.map(({code, description}) =>
                    (code >= 300 && code <= 320 ? {value: code, label: code + ' ' + description} : '')
                );
                const drillingCodes = drillingCodesRaw.filter(item => item !== '');
                setDrillingCostCodes(drillingCodes);

                // Footage codes
                const footageCodesRaw = response.data.map(({code, description}) =>
                    (code >= 600 && code <= 699 ? {value: code, label: code + ' ' + description} : '')
                );
                const footageCodes = footageCodesRaw.filter(item => item !== '');
                setFootageCostCodes(footageCodes);

                // Reaming codes
                const reamingCodesRaw = response.data.map(({code, description}) =>
                    (code >= 550 && code <= 555 ? {value: code, label: code + ' ' + description} : '')
                );
                const reamingCodes = reamingCodesRaw.filter(item => item !== '');
                setReamingCostCodes(reamingCodes);

                // GeneralWorkPerDiemLargeChargesCostCodes codes
                const generalWorkPerDiemLargeChargesCodesRaw = response.data.map(({code, description}) =>
                    (code >= 400 && code <= 440 ? {value: code, label: code + ' ' + description} : '')
                );
                const generalWorkPerDiemLargeChargesCodes = generalWorkPerDiemLargeChargesCodesRaw.filter(item => item !== '');
                setGeneralWorkPerDiemLargeChargesCostCodes(generalWorkPerDiemLargeChargesCodes);

                // Survey codes
                const surveyCodesRaw = response.data.map(({code, description}) =>
                    (code >= 800 && code <= 810 ? {value: code, label: code + ' ' + description} : '')
                );
                const surveyCodes = surveyCodesRaw.filter(item => item !== '');
                setSurveyCostCodes(surveyCodes);

                // Standby codes
                const standbyCodesRaw = response.data.map(({code, description}) =>
                    (code >= 700 && code <= 719 ? {value: code, label: code + ' ' + description} : '')
                );
                const standbyCodes = standbyCodesRaw.filter(item => item !== '');
                setStandbyCostCodes(standbyCodes);

                // Rig Moves
                const rigMovesCodesRaw = response.data.map(({code, description}) =>
                    (code >= 750 && code <= 759 ? {value: code, label: code + ' ' + description} : '')
                );
                const rigMovesCodes = rigMovesCodesRaw.filter(item => item !== '');
                setRigMovesCostCodes(rigMovesCodes);

                // LumpSumsOtherCharges
                const lumpSumsOtherChargesCodesRaw = response.data.map(({code, description}) =>
                    (code >= 100 && code <= 110 ? {value: code, label: code + ' ' + description} : '')
                );
                const lumpSumsOtherChargesCodes = lumpSumsOtherChargesCodesRaw.filter(item => item !== '');
                setLumpSumsOtherCharges(lumpSumsOtherChargesCodes);

                // EquipmentCostCodes
                const equipmentCodesRaw = response.data.map(({code, description}) =>
                    (code >= 450 && code <= 480 ? {value: code, label: code + ' ' + description} : '')
                );
                const equipmentCodes = equipmentCodesRaw.filter(item => item !== '');
                setEquipmentCostCodes(equipmentCodes);

                // WaterHaul
                const waterHaulCodesRaw = response.data.map(({code, description}) =>
                    (code >= 850 && code <= 855 ? {value: code, label: code + ' ' + description} : '')
                );
                const waterHaulCodes = waterHaulCodesRaw.filter(item => item !== '');
                setWaterHaulCostCodes(waterHaulCodes);

                // Auxiliary Equipment
                const auxiliaryEquipmentCodesRaw = response.data.map(({code, description}) =>
                    (code >= 500 && code <= 510 ? {value: code, label: code + ' ' + description} : '')
                );
                const auxiliaryEquipmentCodes = auxiliaryEquipmentCodesRaw.filter(item => item !== '');
                setAuxiliaryEquipmentCostCodes(auxiliaryEquipmentCodes);

                // Consumables
                const consumablesCodesRaw = response.data.map(({code, description}) =>
                    (code >= 2000 && code <= 3150 ? {value: code, label: code + ' ' + description} : '')
                );
                const consumablesCodes = consumablesCodesRaw.filter(item => item !== '');
                setConsumablesCostCodes(consumablesCodes);
            });
    }, [action]);

    // Discipline selection handler
    const disciplineSelectionHandler = (disciplineId) => {
        if (disciplineId > 0) {
            //set url based on action prop
            let url = (action === 'edit') ? "getJobsByDisciplineWithCharges" : "getJobsByDisciplineWithoutCharges";

            // Jobs
            apiClient
                .post(url, {'discipline_id': disciplineId})
                .then(response => {
                    const jobItems = response.data.map(
                        ({id, job_number}) => ({value: id, label: job_number})
                    );
                    setJobs(jobItems);
                });
        }
    }

    // Job selection handler
    const jobSelectionHandler = (jobId) => {
        if (jobId > 0) {
            setSelectedJob(jobId);
        }
        if (action === 'edit') {
            loadJobChargeByJobId(jobId);
        }
    }

    // CSV file operations
    const handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    }

    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    }

    const handleOnRemoveFile = () => {
        window.location.reload();
    }

    // parsing csv
    const handleOnFileLoad = (fileData) => {
        if (fileData !== '') {
            setDisplayFilters(true);
            let data = [];
            fileData.slice(1).forEach((fileRow, index) => {
                const fileRowData = fileRow.data;
                data.push({
                        code: fileRowData[0],
                        revenue_group: fileRowData[1],
                        lost_time_group: fileRowData[2],
                        description: fileRowData[3],
                        price: fileRowData[4],
                        um: fileRowData[5],
                    }
                )
            });
            parseJobCharges(data);
        }
    }

    // parse job charges data
    const parseJobCharges = (data) => {
        let rigActivitiesSectionArray = [];
        let drillingActivitiesSectionArray = [];
        let footageActivitiesSectionArray = [];
        let reamingActivitiesSectionArray = [];
        let generalWorkActivitiesSectionArray = [];
        let surveysActivitiesSectionArray = [];
        let standbyActivitiesSectionArray = [];
        let rigMovesActivitiesSectionArray = [];
        let lumpSumsActivitiesSectionArray = [];
        let equipmentActivitiesSectionArray = [];
        let waterHaulActivitiesSectionArray = [];
        let auxiliaryEquipmentSectionArray = [];
        let consumablesActivitiesSectionArray = [];
        let repairAndMaintenanceSectionArray = [];

        data.forEach((data) => {
            // Repair And Maintenance
            if (data.code >= 1 && data.code <= 99) {
                repairAndMaintenanceSectionArray.push(data)
            }

            // Rig Activities
            if (data.code >= 200 && data.code <= 260) {
                rigActivitiesSectionArray.push(data)
            }

            // drilling
            if (data.code >= 300 && data.code <= 320) {
                drillingActivitiesSectionArray.push(data)
            }

            // reaming
            if (data.code >= 550 && data.code <= 555) {
                reamingActivitiesSectionArray.push(data)
            }

            // General Work
            if (data.code >= 400 && data.code <= 440) {
                generalWorkActivitiesSectionArray.push(data)
            }

            // Surveys
            if (data.code >= 800 && data.code <= 810) {
                surveysActivitiesSectionArray.push(data)
            }

            // Standby
            if (data.code >= 700 && data.code <= 719) {
                standbyActivitiesSectionArray.push(data)
            }

            // Rig Moves
            if (data.code >= 750 && data.code <= 759) {
                rigMovesActivitiesSectionArray.push(data)
            }

            // Lump Sums
            if (data.code >= 100 && data.code <= 110) {
                lumpSumsActivitiesSectionArray.push(data)
            }

            // Equipment
            if (data.code >= 450 && data.code <= 480) {
                equipmentActivitiesSectionArray.push(data)
            }

            // Water Haul
            if (data.code >= 850 && data.code <= 855) {
                waterHaulActivitiesSectionArray.push(data)
            }

            // Auxiliary Equipment
            if (data.code >= 500 && data.code <= 510) {
                auxiliaryEquipmentSectionArray.push(data)
            }

            // Consumables
            if (data.code >= 2000 && data.code <= 3150) {
                consumablesActivitiesSectionArray.push(data)
            }

            // Footage
            if (data.code >= 600 && data.code <= 699) {
                footageActivitiesSectionArray.push(data)
            }
        });

        setRigActivitiesSection(rigActivitiesSectionArray);
        setDrillingSection(drillingActivitiesSectionArray);
        setReamingSection(reamingActivitiesSectionArray);
        setGeneralWorkSection(generalWorkActivitiesSectionArray);
        setSurveysSection(surveysActivitiesSectionArray);
        setStandbySection(standbyActivitiesSectionArray);
        setRigMovesSection(rigMovesActivitiesSectionArray);
        setLumpSumsSection(lumpSumsActivitiesSectionArray);
        setEquipmentSection(equipmentActivitiesSectionArray);
        setWaterHaulSection(waterHaulActivitiesSectionArray);
        setAuxiliaryEquipmentSection(auxiliaryEquipmentSectionArray);
        setConsumablesSection(consumablesActivitiesSectionArray);
        setFootageSection(footageActivitiesSectionArray);
        setRepairAndMaintenanceSection(repairAndMaintenanceSectionArray);
        setIsDataLoaded(true);
    }

    // createJobCharges
    const createJobCharges = (data) => {

        if (!data) {
            return;
        }

        if (isNotValidJobChargesData(data)){
            return;
        }

        setIsLoading(true);
        const url = (action === 'edit') ? 'saveJobCharges' : 'createJobCharge';
        apiClient
            .post(url, JSON.stringify(data))
            .then(response => {
                toast.success(response.data.message);
                setTimeout(() => window.location.reload(), 1000);
            })
            .then(function () {
                setIsLoading(false);
            });
    }

    const isNotValidJobChargesData = (data) => {

        let validationErrors = [];

        if(!hasUniqueCostCode(data.rig_activities_and_work_time)){
            validationErrors.push("Rig Activities & Work Time")
        }

        if(!hasUniqueCostCode(data.drilling)){
            validationErrors.push("Drilling")
        }

        if(!hasUniqueCostCode(data.footage)){
            validationErrors.push("Footage")
        }

        if(!hasUniqueCostCode(data.reaming)){
            validationErrors.push("Reaming")
        }

        if(!hasUniqueCostCode(data.generalWorkPerDiemLargeCharges)){
            validationErrors.push("General Work / Per Diem / Larger Charges")
        }

        if(!hasUniqueCostCode(data.survey)){
            validationErrors.push("Survey")
        }

        if(!hasUniqueCostCode(data.standby)){
            validationErrors.push("Standby")
        }

        if(!hasUniqueCostCode(data.rig_moves)){
            validationErrors.push("Rig Moves")
        }

        if(!hasUniqueCostCode(data.lump_sums_other_charges)){
            validationErrors.push("Lump Sums & Other Charges")
        }

        if(!hasUniqueCostCode(data.equipment)){
            validationErrors.push("Equipment")
        }

        if(!hasUniqueCostCode(data.water_haul)){
            validationErrors.push("Water Haul")
        }

        if(!hasUniqueCostCode(data.auxiliary_equipment)){
            validationErrors.push("Auxiliary Equipment")
        }

        if(!hasUniqueCostCode(data.repair_maintenance)){
            validationErrors.push("Repair & Maintenance")
        }

        if(!hasUniqueCostCode(data.consumables)){
            validationErrors.push("Consumables")
        }

        if (isEmpty(validationErrors)){
            return false;
        }

        toast.error(
            <div>
                <span className="font-weight-bold">
                    Error: Form can not be saved. <br/>
                    Below accordion card(s) include duplicate cost codes:
                </span>
                <ul>
                    {validationErrors.map((item, index) =>
                        <li key={index} >- {item}</li>
                    )}
                </ul>
            </div>
        );

        return true;

    }

    const hasUniqueCostCode = (arr=[], key='code') => {
        const uniqueValues = new Set(arr.map(v=>v[`${key}`]));
        return uniqueValues.size === arr.length;
    }

    // clean form
    const toggleCancel = () => {
        window.location.reload();
    }

    // load job chargeByJobId
    const loadJobChargeByJobId = (jobId) => {
        setRigActivitiesSection([]);
        apiClient
            .post('getJobChargeByJobId', {'job_id': jobId}).then(response => {
                const data = response.data;
                if (data !== '') {
                    parseJobCharges(data);
                }
            });
    }

    const deleteJobChargeByJobId = () => {
        apiClient
            .post('deleteJobChargesByJobId', {'job_id': selectedJob})
            .then(response => {
                toast.success(response.data.message);
                setTimeout(function () {
                    toggleCancel();
                }, 1000);
            })
            .then(function () {
                //close deactivateModal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    const exportJobChargesToCsv = () => {
        apiClient
            .post('downloadJobChargeByJobId', {'job_id': selectedJob})
            .then(response => {
                const blob = new Blob([response.data], {type: response.data.type});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'jobCharges.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            })
    }

    return (
        <>
            <Breadcrumb title={`${action} Job Charges`} parent="Jobs"/>
            <form
                className="needs-validation container-fluid"
                onSubmit={handleSubmit(createJobCharges)}
            >
                <div className="form-row s-gy mb-4">
                    {action === 'upload' &&
                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2">
                            <label htmlFor="csv_file">Upload CSV</label>
                            <CSVReader
                                ref={buttonRef}
                                onFileLoad={handleOnFileLoad}
                                onError={handleOnError}
                                noDrag
                                progressBarColor='#A51E36'
                                config={{}}
                                style={{}}
                                onRemoveFile={handleOnRemoveFile}
                            >
                                {({file}) => (
                                    <aside>
                                        <button
                                            className="btn btn-primary"
                                            type='button'
                                            onClick={handleOpenDialog}
                                        >
                                            Select CSV File
                                        </button>
                                        <div>
                                            {file && file.name}
                                        </div>
                                    </aside>
                                )}
                            </CSVReader>
                        </div>
                    }
                    {(action !== 'upload' || displayFilters) &&
                        <>
                            <FormSelectDaily
                                id='discipline_id'
                                label='Discipline'
                                name='discipline_id'
                                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                onChange={(e) => disciplineSelectionHandler(e.target.value)}
                                options={disciplines}
                                register={register({required: {value: true, message: "required"}})}
                                errors={errors}
                            />
                            <FormSelectDaily
                                id='job_id'
                                label='Job'
                                name='job_id'
                                className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                                onChange={(e) => jobSelectionHandler(e.target.value)}
                                options={jobs}
                                register={register({required: {value: true, message: "required"}})}
                                errors={errors}
                            />
                        </>
                    }
                    {/*<FormButton*/}
                    {/*    className="col-md-1 mb-3"*/}
                    {/*    id='reset_form'*/}
                    {/*    text='Clean Form'*/}
                    {/*    onClick={toggleCancel}*/}
                    {/*    label='Reset Form'*/}
                    {/*    name='reset_form'*/}
                    {/*/>*/}
                </div>
                <div className="card" id="accordion_card">
                    <div className="card-body">
                        <div className="default-according panel-accordion" id="accordionclose">
                            <JobChargesAccordion
                                isDataLoaded={isDataLoaded}
                                selectedJob={Number(selectedJob)}
                                register={register}
                                rigActivitiesCostCodes={rigActivitiesCostCodes}
                                repairAndMaintenanceCostCodes={repairAndMaintenanceCostCodes}
                                repairAndMaintenanceSection={repairAndMaintenanceSection}
                                rigActivitiesSection={rigActivitiesSection}
                                drillingSection={drillingSection}
                                reamingSection={reamingSection}
                                surveysSection={surveysSection}
                                standbySection={standbySection}
                                rigMovesSection={rigMovesSection}
                                waterHaulSection={waterHaulSection}
                                lumpSumsSection={lumpSumsSection}
                                auxiliaryEquipmentSection={auxiliaryEquipmentSection}
                                generalWorkSection={generalWorkSection}
                                consumablesSection={consumablesSection}
                                footageSection={footageSection}
                                equipmentSection={equipmentSection}
                                drillingCostCodes={drillingCostCodes}
                                footageCostCodes={footageCostCodes}
                                reamingCostCodes={reamingCostCodes}
                                surveyCostCodes={surveyCostCodes}
                                standbyCostCodes={standbyCostCodes}
                                rigMovesCostCodes={rigMovesCostCodes}
                                equipmentCostCodes={equipmentCostCodes}
                                waterHaulCostCodes={waterHaulCostCodes}
                                lumpSumsOtherCharges={lumpSumsOtherCharges}
                                generalWorkPerDiemLargeChargesCostCodes={generalWorkPerDiemLargeChargesCostCodes}
                                auxiliaryEquipmentCostCodes={auxiliaryEquipmentCostCodes}
                                consumablesCostCodes={consumablesCostCodes}
                                control={control}
                                errors={errors}
                                setValue={setValue}
                                getValues={getValues}
                                reset={reset}
                            />
                        </div>
                    </div>
                </div>
                {(action !== 'edit' && selectedJob > 0) &&
                    <div className="form-row">
                        <SubmitButton
                            label="Create"
                        />
                        <CancelButton
                            label="Cancel"
                            onClick={toggleCancel}
                        />
                    </div>
                }
                {(action === 'edit' && selectedJob > 0) &&
                    <>
                        <div className="form-row">
                            <SubmitButton
                                label="Save" />
                            <FormSubmitButton
                                btnClassName="btn-primary m-l-5"
                                type="button"
                                label="Delete All"
                                onClick={() => {
                                    setDeleteModal({
                                        message: 'Are you sure that you want to delete Job Charges?',
                                        isOpen: true,
                                    });
                                } } />
                            <FormSubmitButton
                                btnClassName="btn-primary m-l-5"
                                type="button"
                                label="Download CSV"
                                onClick={exportJobChargesToCsv}
                            />
                            <CancelButton
                                label="Cancel"
                                onClick={toggleCancel} />
                        </div>
                    </>
                }
            </form>
            <LoadingIndicator isLoading={isLoading}/>
            <ModalConfirmation
                modal={deleteModal}
                setModal={setDeleteModal}
                title="Delete Job Charges"
                onConfirm={deleteJobChargeByJobId}
            />
        </>
    );
}

export default React.memo(JobCharges);
