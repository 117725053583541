import {AccordionItem} from "react-light-accordion";
import React, {useEffect, useState} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import * as Shared from "../../../../common/shared";
import {useFieldArray} from "react-hook-form";
import SearchableSelectInTable from "../../../../forms/form-control/daily/SearchableSelectInTable";
import apiClient from "../../../../../utils/apiClient";

const DailyFootageDrilled = (props) => {
    const {
        errors, register, control, collectedHoles, drilledDescriptions, action, daily,
        totalDrilled, setTotalDrilled, selectedJobId, setTotalDrilledPrice, totalDrilledPrice,
        setTotalDrilledVariance, totalDrilledVariance, setValue
    } = props;
    const {fields, append, remove} = useFieldArray({control, name: 'drilling'});
    const [selectedDrillingHole, setSelectedDrillingHole] = useState('');

    useEffect(() => {
        if (action === 'edit') {
            append(daily.daily_drilling);

            setTimeout(() => {
                daily.daily_drilling.forEach((item, index) => {
                    // populate name select
                    setValue(
                        `drilling[${index}].cost_code`,
                        {value: item.cost_code, label: item.description}
                    );
                });
            }, 500);

            // this is to show  totals when in edit mode on page reneder
            setTimeout(() => {
                Shared.calculateTotals('total_drilled', setTotalDrilled);
                Shared.calculateTotals('total_drilled_price', setTotalDrilledPrice);
                Shared.calculateTotalVariance(
                    'total_drilled',
                    'total_billable',
                    'total_drilled_price',
                    setTotalDrilledVariance
                );
            }, 200);
        }
    }, [action, append, daily, setTotalDrilled, setTotalDrilledPrice, setTotalDrilledVariance, setValue]);

    const calculateTotalDrilledInRow = (e, from, to, totalId, footageId, priceId, totalPriceId) => {
        e.preventDefault();
        let fromFootage = (document.getElementById(from).value) ? document.getElementById(from).value : 0;
        let toFootage = (document.getElementById(to).value) ? document.getElementById(to).value : 0;
        let price = (document.getElementById(priceId).value) ? document.getElementById(priceId).value : 0;
        let totalRaw = Number(toFootage) - Number(fromFootage);
        let total = Number(totalRaw).toFixed(2);
        document.getElementById(totalId).value = total;
        // if price is set make footage billable the same as total footage
        document.getElementById(footageId).value = (price > 0) ? total : 0;
        document.getElementById(totalPriceId).value =
            Number(price * document.getElementById(footageId).value).toFixed(2);
        Shared.calculateTotals('total_drilled', setTotalDrilled);
        Shared.calculateTotals('total_drilled_price', setTotalDrilledPrice);
    }

    const removeFootageDrilledRow = (index) => {
        remove(index);
        // calculate again
        setTimeout(() => {
            Shared.calculateTotals('total_drilled', setTotalDrilled);
            Shared.calculateTotals('total_drilled_price', setTotalDrilledPrice);
            Shared.calculateTotalVariance(
                'total_drilled',
                'total_billable',
                'total_drilled_price',
                setTotalDrilledVariance
            );
        }, 500);
    }

    const selectCode = (index, value) => {
        let jobId = (action === 'edit') ? daily.job_id : selectedJobId;
        if (value && jobId) {
            apiClient
                .post('getJobChargesPriceByCodeAndJobId', {'job_id': jobId, 'code': value})
                .then(response => {
                    const realPrice = parseFloat(response.data[0]).toFixed(2);
                    document.getElementById(`drilling[${index}].price`).value = realPrice;
                });
        }
    }

    const calculateTotalPriceInRow = (e, price, footageBillable, drillingTotal, totalId) => {
        e.preventDefault();
        let priceValue = (document.getElementById(price) !== null) ? document.getElementById(price).value : 0;
        let footageBillableValue = (document.getElementById(footageBillable) !== null)
            ?
                document.getElementById(footageBillable).value
            :
                0;

        if (footageBillableValue !== 0) {
            let totalRaw = Number(footageBillableValue) * Number(priceValue);
            let total = Number(totalRaw).toFixed(2);
            document.getElementById(totalId).value = total;
            Shared.calculateTotals('total_drilled_price', setTotalDrilledPrice);
        }

        // calculate variance if price > 0
        if (priceValue > 0) {
            Shared.calculateTotalVariance(
                'total_drilled',
                'total_billable',
                'total_drilled_price',
                setTotalDrilledVariance
            );
        }
    }

    useEffect(() => {
        if (!fields.length) {
            setTotalDrilled(0);
            setTotalDrilledPrice(0);
            setTotalDrilledVariance(0);
        }
    }, [fields.length, setTotalDrilled, setTotalDrilledPrice, setTotalDrilledVariance])

    return (
        <AccordionItem className="card-header bg-primary" title="Footage Drilled">
            <div className="card">
                {totalDrilled >= 0 &&
                    <div className="form-row" id="drilled_totals">
                        <div className="col-md-3 mb-3 total_block">
                            <p className="form-control background_total_block_color">
                                Total Drilled: {totalDrilled}
                            </p>
                        </div>
                        {totalDrilledPrice >= 0 &&
                            <div className="col-md-3 mb-3 total_block">
                                <p className="form-control background_total_block_color">
                                    Total Drilled Price: {
                                        Number(totalDrilledPrice)
                                        .toLocaleString('en-US', {style: 'currency', currency: 'USD'})
                                    }
                                </p>
                            </div>
                        }
                        <div className="col-md-3 mb-3 total_block">
                            <p className="form-control background_total_block_color">
                                Total Drilled Variance: {totalDrilledVariance}
                            </p>
                        </div>
                    </div>
                }
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Price</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Total Footage</th>
                                        <th>Footage Billable</th>
                                        <th>Total Price</th>
                                        <th>Hole</th>
                                        <th className="action-cell">
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={() => append({})}
                                            />
                                            <FaEraser
                                                color='#A51E37'
                                                className="marginLeft"
                                                size={20}
                                                title="Remove All"
                                                onClick={() => remove()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => (
                                        <tr key={item.id}>
                                            <SearchableSelectInTable
                                                id={`drilling[${index}].cost_code`}
                                                options={drilledDescriptions}
                                                rules={{ required: "required" }}
                                                control={control}
                                                value={(action === 'edit') ? item.cost_code?.value : ''}
                                                onChange={(value) => selectCode(index, value)}
                                                errors={errors}
                                                customStyles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: 500,
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: 500,
                                                        position: 'sticky',
                                                    })
                                                }}
                                            />
                                            <InputInTable
                                                className="short-select-width"
                                                id={`drilling[${index}].price`}
                                                name={`drilling[${index}].price`}
                                                value={
                                                    (action === 'edit')
                                                        ?
                                                            (item.price)
                                                                ?
                                                                Number(item.price).toFixed(2)
                                                                :
                                                                Number(0).toFixed(2)
                                                        :
                                                            ''
                                                }
                                                register={register({required: false})}
                                                disabled={'true'}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`drilling[${index}].drilling_from`}
                                                name={`drilling[${index}].drilling_from`}
                                                onKeyUp={
                                                    (e) => calculateTotalDrilledInRow(
                                                        e,
                                                        `drilling[${index}].drilling_from`,
                                                        `drilling[${index}].drilling_to`,
                                                        `drilling[${index}].drilling_total`,
                                                        `drilling[${index}].footage_billable`,
                                                        `drilling[${index}].price`,
                                                        `drilling[${index}].total_price`
                                                    )
                                                }
                                                value={(action === 'edit') ? item.drilling_from : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 8)}
                                                register={register({required: "required"})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`drilling[${index}].drilling_to`}
                                                name={`drilling[${index}].drilling_to`}
                                                onKeyUp={
                                                    (e) => calculateTotalDrilledInRow(
                                                        e,
                                                        `drilling[${index}].drilling_from`,
                                                        `drilling[${index}].drilling_to`,
                                                        `drilling[${index}].drilling_total`,
                                                        `drilling[${index}].footage_billable`,
                                                        `drilling[${index}].price`,
                                                        `drilling[${index}].total_price`
                                                    )
                                                }
                                                value={(action === 'edit') ? item.drilling_to : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 8)}
                                                register={register({required: "required"})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                className="total_drilled hours-column-width"
                                                id={`drilling[${index}].drilling_total`}
                                                name={`drilling[${index}].drilling_total`}
                                                disabled={'true'}
                                                value={(action === 'edit') ? item.drilling_total : ''}
                                                register={register({required: false})}
                                            />
                                            <InputInTable
                                                className="total_billable short-select-width"
                                                id={`drilling[${index}].footage_billable`}
                                                name={`drilling[${index}].footage_billable`}
                                                onChange={
                                                    (e) => calculateTotalPriceInRow(
                                                        e,
                                                        `drilling[${index}].price`,
                                                        `drilling[${index}].footage_billable`,
                                                        `drilling[${index}].drilling_total`,
                                                        `drilling[${index}].total_price`
                                                    )
                                                }
                                                value={(action === 'edit') ? item.footage_billable : ''}
                                                onInput={(e) => Shared.limitInputDaily(e.target, 9)}
                                                register={register({required: true})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                className="total_drilled_price price-column-width"
                                                id={`drilling[${index}].total_price`}
                                                name={`drilling[${index}].total_price`}
                                                disabled={'true'}
                                                value={
                                                    (action === 'edit')
                                                        ?
                                                            (item.total_price > 0)
                                                                ?
                                                                    Number(item.total_price).toFixed(2)
                                                                :
                                                                    Number(0).toFixed(2)
                                                        :
                                                            ''
                                                }
                                                register={register({required: "required"})}
                                                errors={errors}
                                            />
                                            {action === 'create' &&
                                                <SelectInTable
                                                    id={`drilling[${index}].hole_name`}
                                                    name={`drilling[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={(collectedHoles.length === 1) ? collectedHoles[0].value : '' }
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            {action === 'edit' &&
                                                <SelectInTable
                                                    id={`drilling[${index}].hole_name`}
                                                    name={`drilling[${index}].hole_name`}
                                                    options={collectedHoles}
                                                    value={
                                                        selectedDrillingHole
                                                            ?
                                                                selectedDrillingHole
                                                            :
                                                                (collectedHoles.length === 1)
                                                                    ?
                                                                    collectedHoles[0].value
                                                                    :
                                                                    item.hole_name
                                                    }
                                                    onChange={
                                                        (e) => Shared.setSelectedValue(e.target.value, setSelectedDrillingHole)
                                                    }
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            <td>
                                                <FaTrash
                                                    color='#A51E37'
                                                    size={20}
                                                    title="Remove"
                                                    onClick={() => removeFootageDrilledRow(index)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default React.memo(DailyFootageDrilled);
