import React from 'react';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import {Bar} from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin);

/**
 * Driller Chart component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DrillerChart = (props) => {
    const {labels, dataset, title, averageValue} = props;

    const averageLine = averageValue ? {
        type: 'line',
        yMin: averageValue,
        yMax: averageValue,
        borderWidth: 3,
        label: {
            display: false,
            content: `Average: ${averageValue}`,
        },
        enter({ element }) {
            element.label.options.display = true;
            return true;
        },
        leave({ element }) {
            element.label.options.display = false;
            return true;
        }
    } : undefined;

    const data = {
        labels: labels,
        datasets: [
            {
                data: dataset,
                backgroundColor: 'rgb(165, 30, 55)',
            },
        ],
    }

    const options = {
        scales: {
            y: {
                title: 'Feet Per Hour',
                beginAtZero: true,
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        maxBarThickness: 20,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                position: 'top',
                color: 'black',
                text: title,
                font: {
                    size: 20,
                    family: "work-Sans, sans-serif",
                }
            },
            annotation: {
                annotations: {
                    average: averageLine,
                },
                drawTime: "afterDraw"
            }
        }
    }

    return <Bar data={data} options={options}/>
}

export default DrillerChart;
